import { showErrorNotification } from '../_shared/views/Notifications';
import { MstGenericResponse } from '@mst-fe/shared/dist/errors/axios-errors';

export interface ErrorNotification {
  message: string;
  description?: string | undefined;
  deduplicationKey?: string;
}

export function handleError(error: ErrorNotification | undefined) {
  if (error) {
    showErrorNotification(error);
  }
}

export function handleAPIResponse(response: MstGenericResponse | undefined): boolean {
  if (!response) {
    showErrorNotification({ message: 'Error contacting server.' });
    return true;
  }

  if (!response.ok) {
    showErrorNotification({ message: response.error });
    return true;
  }

  return false;
}
