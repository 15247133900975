import React, { useContext, useEffect, useMemo, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

import type { MenuProps } from 'antd';
import { Divider, Layout, Menu } from 'antd';
import { ItemType } from 'antd/es/menu/interface';

import {
  ApartmentOutlined,
  AreaChartOutlined,
  CloudServerOutlined,
  DatabaseOutlined,
  DesktopOutlined,
  DollarCircleOutlined,
  FolderOpenOutlined,
  KeyOutlined,
  LineChartOutlined,
  LogoutOutlined,
  MailOutlined,
  RocketOutlined,
  SettingOutlined,
  UserOutlined,
} from '@ant-design/icons';

import useResponsive from '../hooks/use-responsive';
import { AppThemeContext } from '../hooks/use-theme';

import SidebarNameHeader from '../_shared/SidebarNameHeader';
import { SideNavRouteProps } from '../_shared/AppRoutes';
import { BellIconComponent, ItemWithIcon, MoonIconComponent, SunIconComponent } from '../_shared/IconControls';

import { getDashboardNavMain } from '../pages/dashboards/views/DashboardNav';
import { getDocumentationNav } from '../pages/documentation/views/DocumentationNav';
import { getExtAppsNav, getExtAppURL } from '../pages/ext-apps/views/ExtAppsNav';
import { GlobalSearch } from '../pages/global-search/views/GlobalSearch';
import { getInstancesNav } from '../pages/instances/views/InstanceListNav';
import { getJobsNav } from '../pages/jobs/views/JobsNav';
import { getMonitoringAppsNav } from '../pages/monitoring/views/MonitoringAppsNav';
import { getServicesNav } from '../pages/services/views/ServicesNav';
import {
  ADD_GITLAB_API_KEY,
  API_GEN_ITEM_KEY,
  EMAIL_ITEM_KEY,
  FLAG_ENABLE_DATA_LAKE_MONITOR,
  FLAG_ENABLE_WB_INSIDE_ZOE,
  LOGOUT_ITEM_KEY,
  USER_PREFERENCES_KEY,
  USERNAME_ITEM_KEY,
} from '../pages/user-info/models/user-info';
import { AddGitlabApiKeyDialog } from '../pages/user-info/views/AddGitlabApiKeyDialog';
import { GenerateApiKeyDialog } from '../pages/user-info/views/GenerateApiKeyDialog';
import { UserInfo, UserInfoDelegate } from '../pages/user-info/views/UserInfo';
import { UserPreferencesDialog } from '../pages/user-info/views/UserPreferencesDialog';

import { SecondarySidebarState } from '../secondary-sidebar/SecondarySidebar';

import { SignedInUser } from '../services/auth';

import styles from './SideNav.module.css';

type MenuItem = Required<MenuProps>['items'][number];

function getItem(label: React.ReactNode, key: React.Key, children?: MenuItem[], disabled?: boolean): MenuItem {
  return {
    key,
    label,
    children,
    disabled,
  };
}

function getItemWithIcon(label: React.ReactNode, key: React.Key, icon?: React.ReactNode, children?: MenuItem[]): MenuItem {
  return {
    key,
    icon,
    children,
    label,
  };
}

export const DASHBOARDS_ITEM_KEY = '/';

export const DASHBOARDS_ITEM_KEY_PREFIX = '/dashboard/';

export const APPLICATIONS_ITEM_KEY_PREFIX = '/applications/';

export const APPLICATIONS_ITEM_KEY = '/applications';

export const MODULES_KEY = '/modules';

export const PUBLISHED_MANIFESTS_KEY = '/published-manifests';

export const JOBS_ITEM_KEY = '/jobs';

export const JOBS_ITEM_KEY_PREFIX = '/jobs/';

export const RESOURCES_ITEM_KEY = '/resources';

export const DATA_VISUALISATION_ITEM_KEY = '/visualisation';

export const INSTANCES_ITEM_KEY = '/instances';

export const INSTANCE_ITEM_KEY_PREFIX = '/instances/';

export const ORGANIZATION_INSTANCES_ITEM_KEY = '/organisation-instances';

export const THEME_ITEM_KEY = 'theme';

export const NOTIFICATIONS_ITEM_KEY = '/notifications';

export const RELEASE_HISTORY_KEY = 'release-history';

export const BELLPORT_MESSAGE_MAPPING_KEY = 'normalization-guide';

export const FILE_EXPLORER_KEY = '/file-explorer';

export const FILE_EXPLORER_KEY_PREFIX = '/file-explorer/';

export const USAGE_KEY = '/usage';

export const USAGE_KEY_PREFIX = '/usage/';

export const DOCUMENTATION_KEY = '/documentation';

export const DOCUMENTATION_KEY_PREFIX = '/documentation/';

export const DATA_QUERY_KEY = '/data';

export const WORKFLOWS_KEY = '/workflow';

export const MY_DATA_ACTIVITY_KEY = '/my-data';

export const EXT_APPS_KEY = 'ext-apps';

export const MONITORING_APPS_KEY = '/monitoring';

export const MONITORING_APPS_KEY_PREFIX = '/monitoring/';

export const FEEDS_MONITOR_KEY = 'feeds-monitor';

export const STATUS_DASHBOARD_KEY = 'status-dashboard';

export const DATA_LAKE_MONITOR_KEY = 'data-lake-monitor';

const subPathMatches = {
  [DASHBOARDS_ITEM_KEY]: DASHBOARDS_ITEM_KEY_PREFIX,
  [APPLICATIONS_ITEM_KEY]: APPLICATIONS_ITEM_KEY_PREFIX,
  [JOBS_ITEM_KEY]: JOBS_ITEM_KEY_PREFIX,
  [FILE_EXPLORER_KEY]: FILE_EXPLORER_KEY_PREFIX,
  [USAGE_KEY]: USAGE_KEY_PREFIX,
  [DOCUMENTATION_KEY]: DOCUMENTATION_KEY_PREFIX,
  [INSTANCES_ITEM_KEY]: INSTANCE_ITEM_KEY_PREFIX,
  [MONITORING_APPS_KEY]: MONITORING_APPS_KEY_PREFIX,
};

const menuPathOverrides = [
  'workflow',
  'data',
  'file-explorer',
  'documentation/midas-landing-page',
  'documentation/midas-user-guide',
  'documentation/thw-user-guide',
];

export type SideNavProps = {
  onMenuItemClick?: (menuKey: string) => void;
  onSidebarCollapsed?: (collapsed: boolean) => void;
  onNotificationsCollapsed?: (collapsed: SecondarySidebarState) => void;
  notificationsCollapsed?: SecondarySidebarState;
  onToggleNotificationsSidebar?: () => void;
  sidebarCollapsed?: boolean;
  signOut: () => void;
  user?: SignedInUser;
  userInfoDelegate: UserInfoDelegate;
};

function SideNav({
  user,
  signOut,
  onMenuItemClick,
  onSidebarCollapsed,
  sidebarCollapsed,
  notificationsCollapsed,
  dashboardModel,
  dashboardDelegate,
  servicesModel,
  instancesModel,
  instancesDelegate,
  jobsModel,
  userInfoModel,
  userInfoDelegate,
  documentationModel,
  onToggleNotificationsSidebar,
}: SideNavProps & SideNavRouteProps) {
  const wbInsideZoe = userInfoModel.featureFlags.includes(FLAG_ENABLE_WB_INSIDE_ZOE);

  const navigate = useNavigate();
  const location = useLocation();
  const { isMobile } = useResponsive();

  const [selectedKeys, setSelectedKeys] = useState<string[]>([]);
  const [baseOpenKeys, setBaseOpenKeys] = useState<string[]>([]);

  // Force children dropdown to close when parent menu closes
  const [forceCloseChildren, setForceCloseChildren] = useState(false);
  const [animating, setAnimating] = useState<{
    isAnimating: boolean;
    timeout?: NodeJS.Timeout;
  }>({ isAnimating: false });

  const { theme, toggleTheme } = useContext(AppThemeContext);
  const {
    isMidasPeer,
    branding: { instanceName },
    featureFlags: { enableStatusDashboard, enableUsageDashboard, enableDataVisualization },
  } = window.CONFIG;

  const items = useMemo(() => {
    const { workbenchAllowed, featureFlags } = userInfoModel;

    const allowDataLakeMonitor = isMidasPeer || featureFlags.includes(FLAG_ENABLE_DATA_LAKE_MONITOR);

    const commonItems: ItemType[] = [];

    if (workbenchAllowed && dashboardDelegate) {
      commonItems.push(getDashboardNavMain({ model: { ...dashboardModel, openKeys: baseOpenKeys }, delegate: dashboardDelegate }));
    }

    if (workbenchAllowed) {
      commonItems.push(getServicesNav({ model: servicesModel })); // shown to the user as "Applications"

      commonItems.push(getJobsNav({ model: jobsModel }));

      if (wbInsideZoe) {
        const instancesNav = getInstancesNav({
          caption: `${instanceName} Instances`,
          model: instancesModel!,
          delegate: instancesDelegate!,
          isCollapsed: sidebarCollapsed === true,
        })!;
        instancesNav.className = location.pathname === '/instances/' ? 'sidenavButtonPressed' : '';
        commonItems.push(instancesNav);
      } else {
        commonItems.push(
          getItemWithIcon(
            <span data-testid="side-nav-instances">{`${instanceName} Instances`}</span>,
            INSTANCES_ITEM_KEY,
            <DesktopOutlined />
          )
        );
      }

      commonItems.push(
        getItemWithIcon(
          <span data-testid="side-nav-published-manifests">Published Manifests</span>,
          PUBLISHED_MANIFESTS_KEY,
          <RocketOutlined />
        ),
        getItemWithIcon(<span data-testid="side-nav-resources">My Resources</span>, RESOURCES_ITEM_KEY, <CloudServerOutlined />)
      );

      if (enableDataVisualization) {
        commonItems.push(
          getItemWithIcon(
            <span data-testid="side-nav-data-visualisation">Data Visualisation</span>,
            DATA_VISUALISATION_ITEM_KEY,
            <AreaChartOutlined />
          )
        );
      }

      if (!isMidasPeer || enableUsageDashboard) {
        commonItems.push(getItemWithIcon(<span data-testid="side-nav-usage">My Usage</span>, USAGE_KEY, <DollarCircleOutlined />));
      }
    }

    if (!isMidasPeer) {
      commonItems.push(
        getItemWithIcon(<span data-testid="side-nav-file-exchange">File Browser</span>, FILE_EXPLORER_KEY, <FolderOpenOutlined />)
      );
    }

    if (isMidasPeer) {
      commonItems.push(getItemWithIcon(<span data-testid="side-nav-datas-query">Data Query</span>, DATA_QUERY_KEY, <LineChartOutlined />));
      commonItems.push(getItemWithIcon(<span data-testid="side-nav-workflows">Workflows</span>, WORKFLOWS_KEY, <ApartmentOutlined />));
      commonItems.push(getItemWithIcon(<span data-testid="side-nav-activity">Activity</span>, MY_DATA_ACTIVITY_KEY, <DatabaseOutlined />));
    }

    commonItems.push(
      getDocumentationNav({
        model: documentationModel,
      })
    );

    if (allowDataLakeMonitor || enableStatusDashboard) {
      commonItems.push(getMonitoringAppsNav(enableStatusDashboard ?? false, allowDataLakeMonitor ?? false));
    }
    commonItems.push(getExtAppsNav());

    if (!isMobile) {
      return commonItems;
    }

    return [
      ...commonItems,
      getItem(<ItemWithIcon value={user?.attributes?.name ?? ''} Icon={UserOutlined} />, USERNAME_ITEM_KEY, undefined, true),
      getItem(<ItemWithIcon value={user?.attributes?.email ?? ''} Icon={MailOutlined} />, EMAIL_ITEM_KEY, undefined, true),
      workbenchAllowed ? getItem(<ItemWithIcon value="Generate API key" Icon={KeyOutlined} />, API_GEN_ITEM_KEY) : null,
      isMidasPeer ? getItem(<ItemWithIcon value="GitLab API key" Icon={KeyOutlined} />, ADD_GITLAB_API_KEY) : null,
      getItem(<ItemWithIcon value="Logout" Icon={LogoutOutlined} />, LOGOUT_ITEM_KEY),
      getItem(<Divider />, 'divider-1'),
      getItem(<ItemWithIcon value="Toggle Notifications" Icon={BellIconComponent} />, NOTIFICATIONS_ITEM_KEY),
      workbenchAllowed ? getItem(<ItemWithIcon value="Preferences" Icon={SettingOutlined} />, USER_PREFERENCES_KEY) : null,
      getItem(
        <ItemWithIcon
          value={theme === 'light' ? 'Dark Mode' : 'Light Mode'}
          Icon={theme === 'light' ? MoonIconComponent : SunIconComponent}
        />,
        THEME_ITEM_KEY
      ),
    ].filter(Boolean);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    dashboardDelegate,
    servicesModel,
    jobsModel,
    // not used inside, but it seems to affect it anyway?
    forceCloseChildren,
    wbInsideZoe,
    userInfoModel.isAdmin,
    userInfoModel.workbenchAllowed,
    isMidasPeer,
    documentationModel,
    isMobile,
    user?.attributes?.name,
    user?.attributes?.email,
    theme,
    dashboardModel,
    instanceName,
    instancesModel,
    instancesDelegate,
    sidebarCollapsed,
    baseOpenKeys,
  ]);

  useEffect(() => {
    const regex = new RegExp(`^/(${menuPathOverrides.join('|')})`);
    setSelectedKeys([location.pathname.match(regex)?.[0] ?? location.pathname]);
  }, [location.pathname]);

  const handleItemClick: MenuProps['onClick'] = (menuInfo) => {
    setBaseOpenKeys([]);
    // Disable navigation for disabled items
    const eventHtmlElement = menuInfo.domEvent.target as HTMLElement;
    const isMenuDisabled =
      eventHtmlElement.classList.contains('ant-dropdown-menu-item-disabled') ||
      eventHtmlElement.parentElement?.classList.contains('ant-dropdown-menu-item-disabled');
    if (menuInfo.key?.startsWith('/') && !isMenuDisabled) {
      let target = menuInfo.key;
      if (target.endsWith('/_')) {
        target = target.substring(0, target.length - 2);
      }

      onMenuItemClick?.(target);
      navigate(target);
      return;
    }

    if (menuInfo.key?.startsWith(EXT_APPS_KEY)) {
      const appId = menuInfo.key.slice(`${EXT_APPS_KEY}/`.length);
      const appUrl = getExtAppURL(appId);
      if (appUrl) window.open(appUrl, '_blank');

      onMenuItemClick?.(EXT_APPS_KEY);
      return;
    }

    if (menuInfo.key === LOGOUT_ITEM_KEY) {
      signOut();
      return;
    }

    if (menuInfo.key === API_GEN_ITEM_KEY && userInfoDelegate) {
      userInfoDelegate.onStartGenerateApiKey();
    }

    if (menuInfo.key === ADD_GITLAB_API_KEY && userInfoDelegate) {
      userInfoDelegate.onStartAddGitlabApiKey();
    }

    if (menuInfo.key === USER_PREFERENCES_KEY && userInfoDelegate) {
      userInfoDelegate.onOpenUserPreferences();
    }

    if (menuInfo.key === THEME_ITEM_KEY) {
      toggleTheme();
      return;
    }
    //
    // if (menuInfo.key === NOTIFICATIONS_ITEM_KEY) {
    //   onNotificationsCollapsed?.(SecondarySidebarState.Visible);
    //   return;
    // }

    onMenuItemClick?.(menuInfo.key);
  };

  if (!userInfoDelegate) {
    return null;
  }

  const handleOpenChange = (openMenuKeys: string[]) => {
    const openMenuKeysWithoutDuplicates = [...new Set(openMenuKeys)];
    const isOpen = openMenuKeys.length !== 0;
    setForceCloseChildren(!isOpen);
    setBaseOpenKeys(openMenuKeysWithoutDuplicates);
  };

  const openKeys = baseOpenKeys.slice();

  if (!sidebarCollapsed && !animating.isAnimating) {
    for (const [key, prefix] of Object.entries(subPathMatches)) {
      if (selectedKeys.find((k) => k === key || k.startsWith(prefix))) {
        openKeys.push(key);
      }
    }
  } else {
    for (const [key, prefix] of Object.entries(subPathMatches)) {
      if (selectedKeys.find((k) => k === key || k.startsWith(prefix)) && !selectedKeys.includes(key)) {
        selectedKeys.push(key);
      }
    }
  }

  const { splitName } = window.CONFIG.branding;
  const captionText = splitName.primary.toLocaleUpperCase();
  const subHeaderText = sidebarCollapsed ? splitName.secondaryShort : splitName.secondaryLong;

  return (
    <>
      <Layout.Sider
        collapsed={sidebarCollapsed}
        className={`${isMobile ? 'mobile' : 'web'}-sider`}
        width={256}
        aria-label="Navigation Sidebar"
      >
        {!isMobile && (
          <>
            <SidebarNameHeader
              linkTarget={'/'}
              className={'app-header-container'}
              captionText={captionText}
              subHeaderText={subHeaderText}
              captionTextLabel={captionText}
              subHeaderTextLabel={subHeaderText}
            />
            {!sidebarCollapsed && userInfoModel.workbenchAllowed && (
              <div className={styles.globalSearchDesktopContainer}>
                <GlobalSearch />
              </div>
            )}
          </>
        )}
        <Menu
          className={`${isMobile ? 'mobile' : 'web'}-side-menu customize-menu-disable-btn`}
          mode="inline"
          theme={theme === 'light' ? 'light' : undefined}
          inlineIndent={16}
          onClick={handleItemClick}
          selectedKeys={selectedKeys}
          openKeys={openKeys}
          onOpenChange={handleOpenChange}
          items={items}
        />
        {!isMobile && (
          <div className="side-menu-bottom">
            <UserInfo
              model={{ ...userInfoModel, user, sidebarCollapsed, notificationsCollapsed, isMidasPeer }}
              delegate={userInfoDelegate}
              signOut={signOut}
              onSidebarCollapsed={onSidebarCollapsed}
            />
          </div>
        )}
      </Layout.Sider>
      {userInfoModel.generateApiKey && <GenerateApiKeyDialog model={userInfoModel.generateApiKey} delegate={userInfoDelegate} />}
      {userInfoModel.addGitlabApiKey && <AddGitlabApiKeyDialog model={userInfoModel.addGitlabApiKey} delegate={userInfoDelegate} />}
      {userInfoModel.userPreferences && (
        <UserPreferencesDialog
          model={userInfoModel.userPreferences}
          delegate={userInfoDelegate}
          onToggleNotificationsSidebar={onToggleNotificationsSidebar}
        />
      )}
    </>
  );
}

SideNav.defaultProps = {
  onMenuItemClick: undefined,
  sidebarCollapsed: false,
};

export default SideNav;
