/* eslint-disable no-else-return */
import React, { useState, useEffect, useMemo, createRef, useId } from 'react';
import { Button, TimePicker } from 'antd';
import isEqual from 'lodash/isEqual';
import { ClockCircleOutlined, ExclamationCircleOutlined } from '@ant-design/icons';
import InputMask from 'react-input-mask';
import classnames from 'classnames';
import { Dayjs } from 'dayjs';

import classes from './PreciseTimePicker.module.css';
import { PreciseTime } from '../../pages/types';

interface PreciseTimePickerProps {
  time?: string;
  onTimeChange: (values: PreciseTime) => void;
  placeholder?: string;
  withMilliseconds?: boolean;
  withMicroseconds?: boolean;
  americanFormat?: boolean;
  validator?: (values: any) => boolean;
  errorMessage?: React.ReactNode | ((values: any) => React.ReactNode);
  disabled?: boolean;
  autoFocus?: boolean;
  autofillMissingFields?: boolean | string;
}

const getTimeMask = (value: string, withMilliseconds: boolean, withMicroseconds: boolean, americanFormat: boolean) => {
  const thousandMask = [' ', '.', /[0-9]/, /[0-9]/, /[0-9]/];
  const millisecondsSuffix = withMilliseconds ? thousandMask : [];
  const microsecondsSuffix = millisecondsSuffix.length && withMicroseconds ? thousandMask : [];

  if (americanFormat) {
    return [
      /[0-1]/,
      value[0] === '1' ? /[0-2]/ : /[1-9]/,
      ':',
      /[0-5]/,
      /[0-9]/,
      ':',
      /[0-5]/,
      /[0-9]/,
      ' ',
      /[apAP]/,
      'M',
      ...millisecondsSuffix,
      ...microsecondsSuffix,
    ];
  } else {
    return [
      /[0-2]/,
      value[0] === '2' ? /[0-3]/ : /[0-9]/,
      ':',
      /[0-5]/,
      /[0-9]/,
      ':',
      /[0-5]/,
      /[0-9]/,
      ...millisecondsSuffix,
      ...microsecondsSuffix,
    ];
  }
};

const convertTo24Hours = (h: number, period: string) => h - (h === 12 ? 12 : 0) + (period === 'PM' ? 12 : 0);

const getNumberOrNan = (str: string) => (str.includes('_') ? NaN : Number.parseInt(str, 10));

const getCalculatedTimeValues = (currentValue: string, validator = (values: PreciseTime) => true): PreciseTime => {
  const americanPeriod = currentValue.includes('M') ? currentValue.slice(9, 11) : undefined;
  const addedLetters = americanPeriod ? 3 : 0;
  const rawHours = getNumberOrNan(currentValue.slice(0, 2));
  const hours = americanPeriod ? convertTo24Hours(rawHours, americanPeriod) : rawHours;
  const minutes = getNumberOrNan(currentValue.slice(3, 5));
  const seconds = getNumberOrNan(currentValue.slice(6, 8));
  const milliseconds = getNumberOrNan(currentValue.slice(10 + addedLetters, 13 + addedLetters));
  const microseconds = getNumberOrNan(currentValue.slice(15 + addedLetters, 18 + addedLetters));
  // if milli/microsecond is NaN it also gives an information, so they are not instantly set to 0
  const timestamp = (hours * 3600 + minutes * 60 + seconds) * 1000 + (milliseconds || 0);
  const americanPeriodCleared = !americanPeriod || americanPeriod === '_M';
  const isCleared = currentValue.split('').every((char) => Number.isNaN(Number.parseInt(char, 10))) && americanPeriodCleared;
  const hourIsSet = [hours, minutes, seconds].every((val) => Number.isInteger(val)) && americanPeriod !== '_M';

  const values = {
    timestamp,
    microTimestamp: timestamp * 1000 + (microseconds || 0),
    timeString: currentValue,
    hours,
    minutes,
    seconds,
    milliseconds,
    microseconds,
    americanPeriod,
    isCleared,
    hourIsSet,
  } as PreciseTime;

  return {
    ...values,
    isValid: validator(values),
  };
};

const DEFAULT_PROPS = {
  validator: ({ hourIsSet, isCleared }: { hourIsSet: boolean; isCleared: boolean }) => hourIsSet || isCleared,
};

export default function PreciseTimePicker({
  time = '',
  onTimeChange,
  withMilliseconds = true,
  withMicroseconds = true,
  americanFormat = false,
  validator = DEFAULT_PROPS.validator,
  errorMessage = 'Provided time is invalid',
  disabled = false,
  autoFocus = false,
  autofillMissingFields = false,
  ...timePickerProps
}: PreciseTimePickerProps) {
  const [currentValue, setCurrentValue] = useState<string>(time);
  const [showErrorMessage, setShowErrorMessage] = useState<boolean | null>(null);
  const [calculatedValues, setCalculatedValues] = useState<any>({});
  const [repeatBlur, setRepeatBlur] = useState<string | boolean>(false);
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const wrapperRef = createRef<HTMLDivElement>();

  const panelId = useId().replace(/:/g, '');

  useEffect(() => {
    function handlePressEscape(event: KeyboardEvent) {
      if (event.key === 'Escape') {
        setIsOpen(false);
      }
    }

    window.addEventListener('keydown', handlePressEscape);
    return () => {
      window.removeEventListener('keydown', handlePressEscape);
    };
  }, []);

  useEffect(() => {
    function handleClickOutside(event: MouseEvent) {
      const timePickerPanel = document.querySelector(`.ant-picker-time-panel-${panelId}`);
      if (timePickerPanel?.contains && !timePickerPanel.contains(event.target as Node)) {
        setIsOpen(false);
      }
    }

    window.addEventListener('mousedown', handleClickOutside);
    return () => {
      window.removeEventListener('mousedown', handleClickOutside);
    };
  }, [panelId]);

  useEffect(() => {
    setCurrentValue(time);
  }, [time]);

  const mask = useMemo(() => {
    return getTimeMask(currentValue, withMilliseconds, withMicroseconds, americanFormat);
  }, [currentValue, withMilliseconds, withMicroseconds, americanFormat]);

  const { isValid, timeString } = calculatedValues;

  const changeShowErrorState = (invokedByBlur?: boolean) => {
    showErrorMessage !== null || (invokedByBlur && !isValid) ? setShowErrorMessage(!isValid) : '';
  };

  useEffect(() => {
    setCalculatedValues(getCalculatedTimeValues(currentValue, validator));
  }, [currentValue, validator]);

  // JSON.stringify prevent infinite loop with unmemoized validator prop function
  useEffect(() => {
    if (Object.values(calculatedValues).length) {
      changeShowErrorState();
    }
  }, [JSON.stringify(calculatedValues)]);

  /**
   * Update component state and propagate changes to the parent component
   * This should ONLY propagate user changes
   */
  const updateValueAndPropagateChanges = (updatedTime: string): void => {
    setCurrentValue(updatedTime);
    const newCalculatedValues = getCalculatedTimeValues(updatedTime, validator);
    if (Object.values(newCalculatedValues).length && !isEqual(newCalculatedValues, calculatedValues)) {
      onTimeChange(newCalculatedValues);
    }
  };

  const toggleTimePickerHandler = () => {
    if (!document.querySelector('.ant-picker-dropdown:not(.ant-picker-dropdown-hidden)')) {
      setIsOpen(true);
    } else {
      setIsOpen(false);
    }
  };

  // Handle mouse selecting the TimePicker time fields or 'Now' button
  const selectHandler = (selectedTime: Dayjs | undefined) => {
    if (selectedTime) {
      const timeFormat = `${americanFormat ? 'hh' : 'HH'}:mm:ss${americanFormat ? ' A' : ''}`;
      const suffix = withMilliseconds ? currentValue.slice(americanFormat ? 11 : 8) : '';
      updateValueAndPropagateChanges(selectedTime.format(timeFormat) + suffix);
    } else {
      console.error('[selectHandler] selectedTime is not defined.');
    }
  };

  // Handle dropdown Ok button press
  const okHandler = () => {
    setIsOpen(false);
  };

  // Handle keyboard input
  const inputChangeHandler = (e: React.ChangeEvent<HTMLInputElement>) => {
    updateValueAndPropagateChanges(e.target.value.toUpperCase());
  };

  useEffect(() => {
    if (repeatBlur && repeatBlur === timeString) {
      setRepeatBlur(false);
      inputBlurHandler();
    }
  }, [repeatBlur, timeString]);

  const inputBlurHandler = () => {
    const autofillSplitIndex = autofillMissingFields === 'withPrecision' ? 99 : currentValue.indexOf('.');
    const currentHourString = currentValue.substring(0, autofillSplitIndex);
    if (autofillMissingFields && currentHourString.includes('_') && /\d|A|P/.test(currentHourString)) {
      const precisionSuffix = currentValue.substring(autofillSplitIndex);

      let autofilledValue = currentHourString.replace('_M', 'AM').replace(/_/g, '0') + precisionSuffix;
      if (americanFormat && autofilledValue.substr(0, 2) === '00') {
        autofilledValue = autofilledValue.replace('00', '12');
      }
      updateValueAndPropagateChanges(autofilledValue);
      setRepeatBlur(autofilledValue);
      return;
    }
    changeShowErrorState(true);
  };

  const pickerClasses = classnames(
    'ant-picker',
    classes.outline,
    showErrorMessage && classes.invalidTime,
    classes.flexGrow1,
    classes.positionRelative
  );

  return (
    <div className={classes.positionRelative} ref={wrapperRef}>
      <TimePicker
        className={classes.backgroundTimePicker}
        suffixIcon={<ClockCircleOutlined className="clock-dark-customized" />}
        {...timePickerProps}
        defaultValue={undefined}
        value={undefined}
        use12Hours={americanFormat}
        onOk={okHandler}
        onCalendarChange={selectHandler as any}
        popupClassName={`ant-picker-time-panel-${panelId}`}
        open={isOpen}
      />
      <div className={classes.timePickerPosition}>
        <div className={pickerClasses}>
          <div className="ant-picker-input">
            <InputMask
              placeholder={timePickerProps?.placeholder || 'Select time'}
              title=""
              className={classes.inputMask}
              size={18}
              autoComplete="off"
              value={currentValue}
              mask={mask}
              onClick={toggleTimePickerHandler}
              onChange={inputChangeHandler}
              onBlur={inputBlurHandler}
              disabled={disabled}
              // eslint-disable-next-line jsx-a11y/no-autofocus
              autoFocus={autoFocus}
            />
            {showErrorMessage && <ExclamationCircleOutlined className={classes.exclamationMarkIcon} />}
          </div>
        </div>
        <Button style={{ left: '5px' }} onClick={toggleTimePickerHandler} disabled={disabled}>
          <ClockCircleOutlined />
        </Button>
      </div>
      {showErrorMessage && (
        <div className="ant-form-item-explain ant-form-item-explain-error">
          <div role="alert">{typeof errorMessage === 'function' ? errorMessage(calculatedValues) : errorMessage}</div>
        </div>
      )}
    </div>
  );
}
