import React, { ReactEventHandler, useCallback } from 'react';
import { Modal, Space, Input, Spin } from 'antd';
import { KeyOutlined } from '@ant-design/icons';
import { AddGitlabApiKeyModel } from '../models/add-gitlab-api-key';

export interface AddGitlabApiKeyDelegate {
  onCancelAddGitlabApiKey(): void;
  onConfirmAddGitlabApiKey(): Promise<void>;
  onGitlabApiKeyChange(value: string): void;
  onStartAddGitlabApiKey(): void;
}

export function AddGitlabApiKeyDialog({ model, delegate }: { model: AddGitlabApiKeyModel; delegate: AddGitlabApiKeyDelegate }) {
  const handleCancel = useCallback(() => delegate.onCancelAddGitlabApiKey(), [delegate]);
  const handleConfirm = useCallback(() => delegate.onConfirmAddGitlabApiKey(), [delegate]);
  const handleGitlabApiChange = useCallback(
    (evt: React.ChangeEvent<HTMLInputElement>) => delegate.onGitlabApiKeyChange(evt.target.value),
    [delegate]
  );

  return (
    <Modal
      title="Update Gitlab API Key"
      maskClosable={false}
      open
      onCancel={handleCancel}
      onOk={handleConfirm}
      okText="Save"
      okButtonProps={{ disabled: model.loading || model.apiKey.length <= 0 }}
      destroyOnClose
    >
      <Space direction="vertical">
        <span>
          The key will be used to configure the workbench with SSH keys to connect to GitLab. It will also be used to list all repositories.
          To get one please follow below steps:
        </span>
        <ul>
          <li>go to GitLab &gt; User Preferences &gt; Access Tokens</li>
          <li>in &apos;Add a personal access token&apos; section, specify the name for the token</li>
          <li>in &apos;Select scopes&apos; tick api option, complete read/write access to the API</li>
          <li>click &apos;Create personal access token&apos;</li>
          <li>copy the token, paste it below and click &apos;Save&apos;</li>
        </ul>
        <Input
          prefix={model.loading ? <Spin /> : <KeyOutlined />}
          value={model.apiKey}
          allowClear={true}
          onChange={handleGitlabApiChange}
        />
      </Space>
    </Modal>
  );
}
