/* eslint-disable @typescript-eslint/unbound-method */
import { WebViewIpcBase } from './web-view-ipc-base';

export class WebViewIpcServerIFrame extends WebViewIpcBase {
  constructor(
    protected targetWindow: Window,
    protected target?: any,
    protected sessionId: string = ''
  ) {
    super(target, sessionId);
    this.webHandleMessage = this.webHandleMessage.bind(this);
  }

  override fire(callback: () => void) {
    setTimeout(callback, 0);
  }

  override startListening() {
    // eslint-disable-next-line @typescript-eslint/no-misused-promises
    window.addEventListener('message', this.webHandleMessage);
  }

  override stopListening() {
    // eslint-disable-next-line @typescript-eslint/no-misused-promises
    window.removeEventListener('message', this.webHandleMessage);
  }

  protected async webHandleMessage(e: any) {
    return super.handleMessage(e.data);
  }

  override postMessage(message: any): void {
    this.targetWindow.postMessage(message, '*');
  }
}
