// production/dev/staging
export const SHORT_POLLING_INTERVAL = 1500;

export const LONG_POLLING_INTERVAL = 5000;

export const VERY_LONG_POLLING_INTERVAL = 60000;

// for testing on a low bandwidth connection
// export const SHORT_POLLING_INTERVAL = 10000;
// export const LONG_POLLING_INTERVAL = 25000;
// export const VERY_LONG_POLLING_INTERVAL = 60000;

export const DASK_YAML_DOC_URL = 'https://docs.dask.org/en/latest/configuration.html#yaml-files';
