import { NotificationMessage } from '../../../_shared/views/Notifications';
import { ErrorNotification } from '../../../services/error-notification';
import { UsersApi } from '../../users/users-api';
import { UserInfoModel } from './user-info';

export interface AddGitlabApiKeyModel {
  apiKey: string;
  loading: boolean;
}

export function initialGitlabApiKey(): AddGitlabApiKeyModel {
  return { loading: true, apiKey: '' };
}

export function asAddGitlabApiKeyUpdate(model: () => UserInfoModel, update: (_: UserInfoModel) => void) {
  return (addGitlabApiKey: AddGitlabApiKeyModel | undefined) => update({ ...model(), addGitlabApiKey });
}

export async function loadAddGitlabApiKey(
  api: UsersApi,
  update: (_: AddGitlabApiKeyModel) => void
): Promise<ErrorNotification | undefined> {
  update(initialGitlabApiKey());
  const ERROR_MESSAGE = 'Error retrieving the GitLab API Key';
  try {
    const res = await api.getUserGitlabApiKey();
    if (!res?.ok) {
      return { message: ERROR_MESSAGE };
    }
    update({
      loading: false,
      apiKey: res.data?.gitLabApiKey ?? '',
    });
  } catch (error) {
    update({
      loading: false,
      apiKey: '',
    });
    return { message: ERROR_MESSAGE };
  }
}

export async function saveGitlabApiKey(
  api: UsersApi,
  getModel: () => AddGitlabApiKeyModel | undefined,
  update: (_: AddGitlabApiKeyModel | undefined) => void
): Promise<NotificationMessage | undefined> {
  const model = getModel();
  if (!model) {
    return;
  }

  update({ ...model, loading: true });

  const res = await api.putUserGitlabApiKey(model.apiKey);

  if (!res?.ok) {
    update({ ...model, loading: false });
    return { type: 'error', message: 'Error saving the GitLab API Key' };
  }

  update(undefined);
  return {
    type: 'info',
    message: 'Successfully saved Gitlab API Key',
    description: 'The key is securely stored',
  };
}
