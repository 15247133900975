import { WbApi, WbDeploymentType, WbEcsTaskState, WbInstanceData, WbInstanceStatus } from '../workbench-api';

export interface WbInstance {
  id: string;
  name: string;
  shortId: string;
  image: string;
  status: WbInstanceStatus;
  statusOverride: WbInstanceStatus | undefined;
  runningJobs: number;
  progress: number | null | undefined; // 0-1
  createdAt: string; // ISO format
  canUpdate: boolean;
  mem: number;
  cpu: number;
  diskSize: number;
  nEcsTasks: number;
  awsCurrentTaskStatus: WbEcsTaskState | null | undefined;
  awsCurrentTaskId: string | null | undefined;
  consecutiveFailures: number | null | undefined;
  isOpenInsideUI: boolean | undefined;
  scheduledPauseDays: number;
  deploymentType?: WbDeploymentType | undefined;
  lastAccessedAt?: string | undefined;
  logoDownloadUrl: string | undefined;
}

export interface NonOwnedInstance {
  id: string;
  name: string;
  createdAt: string;
  performingRetake: boolean;
  status?: WbInstanceStatus;
}

function instanceFromData(existingData: WbInstance[], data: WbInstanceData): WbInstance {
  const matchingRecord = existingData.find((d) => d.id === data.id);

  return {
    id: data.id,
    name: data.uiData?.name ?? data.shortId,
    shortId: data.shortId,
    image: data.awsInfo?.ECRImageId ?? 'N/A',
    status: data.currentStatus,
    statusOverride: matchingRecord?.statusOverride,
    runningJobs: data.runningJobs,
    progress: data.provisioningInfo?.progress,
    createdAt: data.created_at,
    canUpdate: data.canUpdate,
    mem: data.mem,
    cpu: data.cpu,
    diskSize: data.diskSize,
    nEcsTasks: data.nEcsTasks,
    awsCurrentTaskStatus: data.awsCurrentTaskStatus,
    awsCurrentTaskId: data.awsCurrentTaskId,
    consecutiveFailures: data.consecutiveFailures,
    isOpenInsideUI: false,
    scheduledPauseDays: data.scheduledPauseDays,
    deploymentType: data.deploymentType,
    logoDownloadUrl: data.logoDownloadUrl,
  };
}

export async function loadInstances(api: WbApi, getExisting: () => WbInstance[]) {
  const [actualInstances, instancesOwnedByOthersResponse] = await Promise.all([api.getInstances(), api.getInstancesOwnedByOthers()]);
  if (!actualInstances || !instancesOwnedByOthersResponse) {
    return undefined;
  }

  const existingData = getExisting();
  const instances = actualInstances.map((data) => instanceFromData(existingData, data));

  const instancesOwnedByOthers = instancesOwnedByOthersResponse.instances.map(
    (data): NonOwnedInstance => ({
      id: data.id,
      name: data.uiData?.name ?? data.shortId,
      createdAt: data.created_at,
      performingRetake: false,
      status: data.currentStatus,
    })
  );

  return { instances, instancesOwnedByOthers };
}
