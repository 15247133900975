/**
 * An error that keeps track of its cause and origin.
 *
 * Useful to have full stack information even in event handlers.
 */
export class LinkedError<TCause = unknown> extends Error {
  constructor(
    message: string,
    public readonly origin: Error | undefined,
    public readonly cause: TCause
  ) {
    super(message);
  }
}
