import React from 'react';
import { Dayjs } from 'dayjs';

import GenericOutputContainer from './controls/GenericOutputContainer';
import OrderBookGridControl from './controls/orderbook/OrderBookGridControl';
import OrderBookGraphControl from './controls/orderbook/OrderBookGraphControl';

import useProductDataRequester from './hooks/use-product-data-requester';

import { buildUrl } from './_shared/misc-utils';

import { DataQueryConfig } from '../../../types';
import { ROOT_SERVER_URL, WS_SERVER_URL } from '../../../../utils/midas-constants';
import { isDayjs } from '../../../../utils/config-utils';

const ORDER_BOOK_QUERY_URL = `${ROOT_SERVER_URL}api/querying/orderbook`;
const ORDER_BOOK_QUERY_WS_URL = `${WS_SERVER_URL}api/querying/orderbook`;

const getUrlForQuery = (query: DataQueryConfig, selectedProductForDownload?: string, downloadType = 'text/csv', useWS = false) => {
  if (!isDayjs(query?.date)) {
    return;
  }
  // workaround isDayjs() type inference issue
  const queryDate = query?.date as Dayjs;

  const time = query.time && isDayjs(query.time) ? (query.time as Dayjs).format('HH:mm:ss.SSS') : query.time;

  const sharedParams = {
    download: (selectedProductForDownload !== undefined).toString(),
    source: query.source,
    feed: query.feed,
    date: queryDate.format('YYYYMMDD'),
    time: time,
    numberOfLevels: query.numberOfLevels ?? 20,
  };

  if (selectedProductForDownload) {
    return buildUrl(ORDER_BOOK_QUERY_URL, {
      ...sharedParams,
      product: selectedProductForDownload,
      downloadType,
    });
  }
  const orderBookUrl = useWS ? ORDER_BOOK_QUERY_WS_URL : ORDER_BOOK_QUERY_URL;

  return query.products?.map((product: string) =>
    buildUrl(orderBookUrl, {
      ...sharedParams,
      product,
    })
  );
};

const getUrlForGraphQuery = (query: DataQueryConfig, product?: string) => {
  if (!isDayjs(query?.date)) {
    return;
  }
  // workaround isDayjs() type inference issue
  const queryDate = query?.date as Dayjs;

  return buildUrl(`${ORDER_BOOK_QUERY_URL}/visualize`, {
    source: query.source,
    feed: query.feed,
    date: queryDate.format('YYYYMMDD'),
    product,
  });
};

const DEFAULT_PROPS = {
  onConfigChanged: () => {},
};

export default function OrderBookOutput({
  config,
  onConfigChanged = DEFAULT_PROPS.onConfigChanged,
}: {
  config: DataQueryConfig;
  onConfigChanged?: (config: DataQueryConfig) => void;
}) {
  const { downloadedData, setProduct, product, handleDownloadData, processing } = useProductDataRequester({
    config,
    getUrlForQuery,
  });

  return (
    <GenericOutputContainer
      config={config}
      gridControl={OrderBookGridControl}
      tableColumns={[]}
      data={downloadedData}
      dataLoading={processing.busy}
      dataError={processing.error}
      products={config?.products || []}
      product={product}
      onDownload={handleDownloadData}
      onSelectProduct={setProduct}
      graphControl={OrderBookGraphControl}
      getUrlForGraphQuery={getUrlForGraphQuery}
      onConfigChanged={onConfigChanged}
      graphData={undefined}
      graphDataLoading={undefined}
      onCursorBack={undefined}
      onCursorNext={undefined}
      onPageSizeChanged={undefined}
      onChangedFilters={undefined}
      xAxisColumn={undefined}
      xAxisColumnType={undefined}
      xAxisLabelColumn={undefined}
    />
  );
}
