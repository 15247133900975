import React from 'react';

import { ItemType } from 'antd/lib/menu/interface';
import { MonitorOutlined } from '@ant-design/icons';

import {
  DATA_LAKE_MONITOR_KEY,
  FEEDS_MONITOR_KEY,
  MONITORING_APPS_KEY,
  MONITORING_APPS_KEY_PREFIX,
  STATUS_DASHBOARD_KEY,
} from '../../../side-nav/SideNav';

function getMonitoringAppsNavItems(showStatusDashboard: boolean, showDataLakeMonitor: boolean): ItemType[] {
  const items = [];
  if (showDataLakeMonitor) {
    items.push({
      label: 'Feeds Monitor',
      icon: <MonitorOutlined />,
      key: `${MONITORING_APPS_KEY_PREFIX}${FEEDS_MONITOR_KEY}`,
    });
  }
  if (showStatusDashboard) {
    items.push({
      label: 'Status Dashboard',
      icon: <MonitorOutlined />,
      key: `${MONITORING_APPS_KEY_PREFIX}${STATUS_DASHBOARD_KEY}`,
    });
  }
  if (showDataLakeMonitor) {
    items.push({
      label: 'Data Lake Monitor',
      icon: <MonitorOutlined />,
      key: `${MONITORING_APPS_KEY_PREFIX}${DATA_LAKE_MONITOR_KEY}`,
    });
  }

  return items;
}

export function getMonitoringAppsNav(showStatusDashboard: boolean, showDataLakeMonitor: boolean): ItemType {
  return {
    key: MONITORING_APPS_KEY,
    label: 'Monitoring Apps',
    icon: <MonitorOutlined />,
    children: getMonitoringAppsNavItems(showStatusDashboard, showDataLakeMonitor),
  };
}
