import { ErrorNotification } from '../../../services/error-notification';
import { AppStoreApi } from '../../modules/app-store-api';
import { WbInstance } from './instance';

export interface InstanceRemove extends Pick<WbInstance, 'id' | 'status'> {
  existingFiles: string[];
  fileCount: number;
  totalSize: number;
  loading: boolean;
  error: string | undefined;
  hasPublishedModules: boolean;
}

let removeSequence = 0;

export function createLoadingInstanceRemove<T extends { confirmRemoveInstance: InstanceRemove | undefined }>(
  model: () => T,
  instance: Pick<WbInstance, 'id' | 'status' | 'shortId'>,
  dataGetter: (id: string) => Promise<
    | {
        files: string[];
        totalSize: number;
      }
    | undefined
  >,
  updater: (newModel: T) => void,
  errorHandler: (error: ErrorNotification) => void
): T {
  const {
    branding: { instanceName },
  } = window.CONFIG;
  const currentRemoveSequence = ++removeSequence;
  const modulesApi = new AppStoreApi();

  setTimeout(async () => {
    try {
      const userPublishedModules = await modulesApi.getUserPublishedModules();
      const hasPublishedModules = !!userPublishedModules?.filter((module) => module.wbShortId === instance.shortId).length;
      const fileSummary = await dataGetter(instance.id);
      const currentModel = model();
      // check that the dialog is still open, showing this instance, and the user didn't close and re-open it (thus triggering a second request)
      if (currentModel.confirmRemoveInstance && currentRemoveSequence === removeSequence) {
        updater(createLoadingInstanceRemovePopulated(currentModel, fileSummary, hasPublishedModules));
      }
    } catch (e) {
      console.error(e);

      errorHandler({
        message: `Could not check for files in the ${instanceName} instance's directory; they will all be deleted if you remove this ${instanceName} instance!`,
      });
    }
  }, 0);

  return {
    ...model(),
    confirmRemoveInstance: {
      id: instance.id,
      status: instance.status,
      existingFiles: [],
      fileCount: 0,
      totalSize: 0,
      loading: true,
      error: undefined,
      hasPublishedModules: false,
    },
  };
}

function createLoadingInstanceRemovePopulated<T extends { confirmRemoveInstance: InstanceRemove | undefined }>(
  model: T,
  apiResponse:
    | {
        files: string[];
        totalSize: number;
      }
    | undefined,
  hasPublishedModules: boolean
): T {
  if (!model.confirmRemoveInstance) {
    return model;
  }

  return {
    ...model,
    confirmRemoveInstance: {
      ...model.confirmRemoveInstance,
      existingFiles: apiResponse?.files || [],
      fileCount: apiResponse?.files?.length || 0,
      totalSize: apiResponse?.totalSize || 0,
      loading: false,
      error: !apiResponse ? 'Unable to get information.' : undefined,
      hasPublishedModules,
    },
  };
}
