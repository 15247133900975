import { handleError } from '../../services/error-notification';
import { PeriodicRequest } from '../../services/periodic-request';
import { showNotification } from '../../_shared/views/Notifications';
import { JobExecution, JobListItem, JobsApi } from './jobs-api';
import {
  asEditJobUpdate,
  asJobsListDetaileUpdate,
  asTriggerJobUpdate,
  closeExecutionLogs,
  initialJobsList,
  interruptJobExecution,
  JobsList,
  loadExecutionLogs,
  loadJobDetail,
  setJobExecutionToInterrupt,
  updateJobExecutions,
  updateJobsListItems,
} from './models/jobs-list';
import { deleteJob, loadEditJob, toggleJobEnabled, updateJob } from './models/job-edit';
import { JobDetailsDelegate } from './views/JobDetails';
import { JobsMenuDelegate } from './views/JobsMenu';
import { editTriggerJobPayload, initialTriggerJobModel, saveTriggerJob } from './models/trigger-job';
import { ConfigSchemaFormModel } from '../../_shared/models/config-schema-form-model';
import { LONG_POLLING_INTERVAL } from '../../constants';

export class JobsController implements JobDetailsDelegate, JobsMenuDelegate {
  private model = initialJobsList();

  private disposeCallbacks: (() => void)[] = [];

  private jobsPeriodicRequest: PeriodicRequest<JobListItem[] | undefined>;
  private executionsPeriodicRequest: PeriodicRequest<JobExecution[] | undefined>;

  private getModel = (): JobsList => this.model;

  private update = (model: JobsList) => {
    this.model = model;
    this.updateViewState(model);
  };

  private updateJobDetail = asJobsListDetaileUpdate(this.getModel, this.update);

  private updateEditJob = asEditJobUpdate(this.getModel, this.update);

  private updateTriggerJob = asTriggerJobUpdate(this.getModel, this.update);

  constructor(
    private updateViewState: (_: JobsList) => void,
    private api = new JobsApi()
  ) {
    this.jobsPeriodicRequest = new PeriodicRequest({
      interval: LONG_POLLING_INTERVAL,

      onPeriodicRequest: async () => await this.api.list(),

      onPeriodicRequestResult: (value: JobListItem[] | undefined) => {
        if (value) {
          this.update(updateJobsListItems(this.model, value));
        }
      },
    });

    let lastRequestJobId: string | undefined;
    this.executionsPeriodicRequest = new PeriodicRequest({
      interval: LONG_POLLING_INTERVAL,

      onPeriodicRequest: async () => {
        lastRequestJobId = this.model.jobDetail?.jobId;
        if (lastRequestJobId) {
          return await this.api.jobExecutions(lastRequestJobId);
        }
      },

      onPeriodicRequestResult: (value: JobExecution[] | undefined) => {
        if (value && this.model.jobDetail?.jobId === lastRequestJobId) {
          updateJobExecutions(this.model, this.updateJobDetail, value);
        }
      },
    });
  }

  start() {
    if (this.disposeCallbacks.length === 0) {
      this.disposeCallbacks.push(this.jobsPeriodicRequest.start());
      this.disposeCallbacks.push(this.executionsPeriodicRequest.start());
    }
  }

  dispose() {
    for (const cb of this.disposeCallbacks) {
      cb();
    }
    this.disposeCallbacks = [];
  }

  async onEditJob(job: JobListItem): Promise<void> {
    handleError(await loadEditJob(this.api, job, () => this.model.editJob, this.updateEditJob));
  }

  async onDeleteJob(job: JobListItem, navigate: (target: string) => void): Promise<void> {
    const result = await deleteJob(this.api, job, this.jobsPeriodicRequest.refresh);
    if (result?.type !== 'error' && window.location.pathname.startsWith(`/jobs/${job.id}`)) {
      navigate('/published-manifests');
    }

    if (result) {
      showNotification(result);
    }
  }

  async onEnableDisableJob(job: JobListItem): Promise<void> {
    handleError(await toggleJobEnabled(this.api, job, this.jobsPeriodicRequest.refresh));
  }

  async onEditJobConfirm(): Promise<void> {
    handleError(await updateJob(this.api, () => this.model.editJob, this.jobsPeriodicRequest.refresh, this.updateEditJob));
  }

  async onLoadJobDetails(jobId: string): Promise<void> {
    handleError(await loadJobDetail(this.api, this.model, this.updateJobDetail, jobId));
  }

  async onViewExecutionLogs(execution: JobExecution): Promise<void> {
    handleError(await loadExecutionLogs(this.api, this.getModel, this.update, execution.id));
  }

  // eslint-disable-next-line @typescript-eslint/require-await
  async onInterruptExecution(execution: JobExecution): Promise<void> {
    this.update(setJobExecutionToInterrupt(this.model, execution));
  }

  // eslint-disable-next-line @typescript-eslint/require-await
  async onCancelInterruptExecution(): Promise<void> {
    this.update(setJobExecutionToInterrupt(this.model, undefined));
  }

  async onDoInterruptExecution(): Promise<void> {
    handleError(await interruptJobExecution(this.api, this.getModel, this.update));
  }

  onCancelEditJob(): void {
    this.updateEditJob(undefined);
  }

  onConfigChange(config: ConfigSchemaFormModel['config']): void {
    if (this.model.editJob) {
      this.updateEditJob({ ...this.model.editJob, config });
    }
  }

  onViewLogsClose(): void {
    this.update(closeExecutionLogs(this.model));
  }

  async onViewLogsRefresh(): Promise<void> {
    const id = this.model.jobExecutionLogs?.id;
    if (!id) {
      return;
    }

    handleError(await loadExecutionLogs(this.api, this.getModel, this.update, id));
  }

  onTriggerJob(job: JobListItem): void {
    this.updateTriggerJob(initialTriggerJobModel(job.id));
  }

  onTriggerJobPayload(value: string): void {
    if (this.model.triggerJob) {
      this.updateTriggerJob(editTriggerJobPayload(this.model.triggerJob, value));
    }
  }

  onTriggerJobCancel(): void {
    this.updateTriggerJob(undefined);
  }

  async onTriggerJobConfirm(): Promise<void> {
    if (this.model.triggerJob) {
      handleError(await saveTriggerJob(this.api, () => this.model.triggerJob, this.jobsPeriodicRequest.refresh, this.updateTriggerJob));
    }
  }
}
