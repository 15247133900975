import React, { useState, useEffect, useMemo } from 'react';
import { Form, Button, InputNumber, FormInstance, DatePicker } from 'antd';
import { Dayjs } from 'dayjs';

import PreciseTimePicker from '../../../../_shared/preciseTimePicker/PreciseTimePicker';
import SelectEquityControl from './controls/SelectEquityControl';
import { preventEnterKeyDefault, shouldChangesDisableProducts, shouldDisableProducts } from './data-query-form-utils';
import { AMERICAN_DATE_FORMAT, FORM_LAYOUT_LABEL } from '../../../../utils/midas-constants';

import classes from './shared.module.css';
import { DataQueryConfig, PreciseTime } from '../../../types';
import { isDayjs } from '../../../../utils/config-utils';

type OrderBookFormParams = {
  config: DataQueryConfig;
  formInstance: FormInstance;
  onFinish?: (values: unknown) => void;
};

const validator = ({ hourIsSet, americanPeriod }: PreciseTime) => hourIsSet && americanPeriod !== '_M';

const DATE_FIELD_NAME = 'date';

const DEFAULT_PROPS = {
  onFinish: () => {},
};

// Matches:
// 15:44:47
// or
// 15:44:47.124
const TIME_REGEX = new RegExp(/^\d\d:\d\d:\d\d(\.\d\d\d)?$/);

const validateTime = (rawTime: string | Dayjs): boolean => {
  if (typeof rawTime === 'string') {
    return TIME_REGEX.test(rawTime);
  } else if (isDayjs(rawTime)) {
    const time = rawTime.format('HH:mm:ss.SSS');
    return TIME_REGEX.test(time);
  }
  return false;
};

export default function OrderBookForm({ config, formInstance, onFinish = DEFAULT_PROPS.onFinish }: OrderBookFormParams) {
  const formValue = Form.useWatch([], formInstance);

  const [hasLevel, setLevelStatus] = useState(!!config?.numberOfLevels);
  const [disableProducts, setDisableProducts] = useState(false);
  const [orderBookFormValid, setOrderBookFormValid] = useState(false);

  const currentTime = useMemo(() => {
    if (isDayjs(config?.time)) {
      return (config.time as Dayjs).format('HH:mm:ss.SSS');
    } else if (typeof config?.time === 'string') {
      return config.time;
    }
    return '';
  }, [config?.time]);

  const setFormValidity = async () => {
    try {
      await formInstance.validateFields({ validateOnly: true });
      setOrderBookFormValid(true);
    } catch (errorInfo: unknown) {
      setOrderBookFormValid((errorInfo as { errorFields: unknown[] }).errorFields?.length === 0);
    }
  };

  useEffect(() => {
    if (!formValue) return;

    const shouldDisable = shouldChangesDisableProducts({
      formInstance,
      changedValues: formValue,
      dateFieldName: DATE_FIELD_NAME,
    });

    setDisableProducts(shouldDisable);

    setTimeout(async () => await setFormValidity());
  }, [formValue]);

  const handleTimeChange = ({ timeString: time }: PreciseTime) => {
    formInstance.setFieldsValue({ time: time.replace(/(\._+)+| /g, '') });
  };

  useEffect(() => {
    // eslint-disable-next-line @typescript-eslint/require-await
    const fetchData = async () => {
      const shouldDisable = shouldDisableProducts({ formInstance, dateFieldName: DATE_FIELD_NAME, includeFeed: false });
      setDisableProducts(shouldDisable);

      setLevelStatus(!!config.numberOfLevels);

      setTimeout(async () => await setFormValidity());
    };
    void fetchData();
  }, [config]);

  return (
    <Form
      form={formInstance}
      layout="horizontal"
      labelCol={FORM_LAYOUT_LABEL}
      onFinish={onFinish}
      labelWrap
      onKeyDown={preventEnterKeyDefault}
    >
      <Form.Item label="Date" name={DATE_FIELD_NAME} rules={[{ required: true, message: 'Please select date.' }]} labelAlign="left">
        <DatePicker format={AMERICAN_DATE_FORMAT} aria-label="Date picker" />
      </Form.Item>
      <Form.Item
        label="Time"
        name="time"
        rules={[
          { required: true, message: 'Please specify time.' },
          {
            validator: (_, value) => {
              if (validateTime(value)) {
                return Promise.resolve();
              }
              return Promise.reject(new Error('Please specify correctly formatted time: hh:mm:ss or hh:mm:ss.mmm'));
            },
          },
        ]}
        labelAlign="left"
      >
        <PreciseTimePicker
          time={currentTime}
          onTimeChange={handleTimeChange}
          autofillMissingFields
          withMicroseconds={false}
          validator={validator}
        />
      </Form.Item>
      <SelectEquityControl
        config={config}
        disabled={disableProducts}
        dateFieldName={DATE_FIELD_NAME}
        form={formInstance}
        selectFeed={false}
        excludeFeedFamilies={['fi', 'quotes']}
      />

      <Form.Item
        label="Levels"
        name="numberOfLevels"
        extra={!hasLevel ? <i>(default: 20)</i> : null}
        rules={[
          () => ({
            async validator(rule, value) {
              setLevelStatus(!!value);
              return Promise.resolve();
            },
          }),
        ]}
        labelAlign="left"
      >
        <InputNumber min={0} />
      </Form.Item>
      <Form.Item>
        <Button className={classes.applyButtonContainer} htmlType="submit" type="primary" disabled={!orderBookFormValid}>
          Run Query
        </Button>
      </Form.Item>
    </Form>
  );
}
