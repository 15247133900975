import React from 'react';

import { useController } from '../../../hooks/use-controller';

import { initialDataLakeMonitorStore } from '../models/data-lake-monitor-store';

import { DataLakeMonitorController } from '../data-lake-monitor-controller';

import { DataLakeStatusMonitorView } from './DataLakeStatusMonitorView';

export default function DataLakeStatusMonitor() {
  const { model, delegate } = useController(initialDataLakeMonitorStore, DataLakeMonitorController);
  return (delegate && <DataLakeStatusMonitorView model={model} delegate={delegate} />) ?? null;
}
