import { env } from 'process';

import loggerFactory, { Logger, Bindings, TransportTargetOptions } from 'pino';
import rTracer from 'cls-rtracer';

function getFormattingLogger(): TransportTargetOptions {
  return {
    target: 'pino-pretty',
    level: 'info',
    options: {
      colorize: true,
      translateTime: true,
      singleLine: true,
    },
  };
}

let baseLogger: Logger;

export const getLogger = (bindings: Bindings): Logger => {
  if (!baseLogger) {
    const isDev = env.MST_NODE_ENV === 'sandbox' || env.NODE_ENV === 'development' || env.NODE_ENV === 'test';
    const isTest = env.NODE_ENV === 'test';

    const defaultLevel = isDev ? 'trace' : 'debug';

    baseLogger = loggerFactory({
      enabled: true,
      timestamp: loggerFactory.stdTimeFunctions.isoTime,
      level: env.PINO_LOG_LEVEL || defaultLevel,
      transport: isDev && !isTest ? { targets: [getFormattingLogger()] } : undefined,
      base: undefined,
      mixin() {
        return { requestId: rTracer.id() };
      },
    });
  }

  return baseLogger.child(bindings);
};
