import React from 'react';

import styles from './SidebarButtons.module.css';

export function SidebarButtons({ sidebarCollapsed, children }: { sidebarCollapsed: boolean | undefined; children: React.ReactNode }) {
  return (
    <div className={`${styles.sidebarButtonsContainer} ${sidebarCollapsed ? styles.contractedContainer : styles.expandedContainer}`}>
      {children}
    </div>
  );
}
