import dayjs from 'dayjs';
import omit from 'lodash/omit';
import get from 'lodash/get';
import { TableProps } from 'antd';
import { SortOrder, SorterResult } from 'antd/lib/table/interface';

import { DataQueryFormItemMetaTableConfig, DataQueryTableConfigSorting } from '../../../../types';
import { defaultSort } from '../../../../../../utils/tables';

export const DEFAULT_PAGE_SIZE = 50;

export type SorterFactory = (
  dataIndex: string,
  isDate?: boolean
) => { sorter: (a: object, b: object) => number; sortOrder: SortOrder | undefined };

export const createSorterFactory =
  (currentConfig?: DataQueryTableConfigSorting) =>
  (tableName: string): SorterFactory =>
  (dataIndex, isDate) => {
    const sortOrder = currentConfig?.[tableName]?.column === dataIndex ? currentConfig?.[tableName]?.order : undefined;

    return {
      sorter: (a: object, b: object) => {
        const lhs = get(a, dataIndex);
        const rhs = get(b, dataIndex);
        return isDate && dayjs(lhs).isValid() ? dayjs(lhs).diff(rhs) : defaultSort(lhs, rhs);
      },
      sortOrder,
    };
  };

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export type SorterCallback = (tableName: string) => TableProps<any>['onChange'];

export const createSorterCallback =
  (onTableConfigChanged?: (fb: (value: DataQueryFormItemMetaTableConfig) => DataQueryFormItemMetaTableConfig) => void): SorterCallback =>
  (tableName) =>
  (pagination, filter, sort) => {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const { order, column: { dataIndex } = {} } = sort as SorterResult<any>;

    if (!onTableConfigChanged) {
      return;
    }
    if (!dataIndex) {
      onTableConfigChanged((c) => ({
        ...c,
        sorting: omit(c?.sorting || {}, [tableName]),
      }));
    } else {
      onTableConfigChanged((c) => ({
        ...c,
        sorting: {
          ...c?.sorting,
          [tableName]: {
            column: dataIndex,
            order,
          },
        },
      }));
    }
  };
