import { initialNotificationsListModel } from './models/notifications-list';
import { NotificationController } from './notifications-controller';

import { useController } from '../../../hooks/use-controller';

export function useNotifications() {
  const { model: notificationsModel, delegate: notificationsDelegate } = useController(
    initialNotificationsListModel,
    NotificationController
  );
  return { notificationsModel, notificationsDelegate };
}
