import { WbEcsTaskState } from '../instances/workbench-api';
import { NonOwnedInstance, WbInstance } from '../instances/models/instance';
import { AlertProps } from 'antd';
import { OrgInstance } from '../organization-instances/models/organization-instance';

type WbInstanceStatusProps = Pick<WbInstance, 'status' | 'nEcsTasks' | 'awsCurrentTaskStatus'> &
  Partial<Pick<WbInstance, 'statusOverride' | 'consecutiveFailures'>>;

export type WbInstanceStatus =
  | 'PROVISIONING'
  | 'REMOVING'
  | 'PROVISIONED'
  | 'PROVISIONING_ERROR'
  | 'REMOVING_ERROR'
  | 'RESTARTING'
  | 'RETURNING'
  | 'RETRYING_REQUEST'
  | 'REMOVING_REQUEST'
  | 'PAUSING'
  | 'PAUSED'
  | 'DELETED';

export const statusToLabel: { [key in WbInstanceStatus]: string } = {
  PROVISIONING: 'Provisioning',
  REMOVING: 'Removing',
  PROVISIONED: 'Provisioned',
  PROVISIONING_ERROR: 'Error provisioning',
  REMOVING_ERROR: 'Error removing',
  RESTARTING: 'Restarting',
  RETURNING: 'Returning',
  RETRYING_REQUEST: 'Retrying',
  REMOVING_REQUEST: 'Removing',
  PAUSED: 'Paused',
  PAUSING: 'Pausing',
  DELETED: 'Deleted',
};

export const statusToPillType: { [key in WbInstanceStatus]: 'success' | 'info' | 'warning' | 'error' } = {
  PROVISIONING: 'info',
  REMOVING: 'info',
  PROVISIONED: 'success',
  PROVISIONING_ERROR: 'error',
  REMOVING_ERROR: 'error',
  RESTARTING: 'info',
  RETURNING: 'info',
  RETRYING_REQUEST: 'info',
  REMOVING_REQUEST: 'info',
  PAUSED: 'warning',
  PAUSING: 'warning',
  DELETED: 'error',
};

export const statusTypeMap: { [key: string]: AlertProps['type'] } = {
  PROVISIONING: 'info',
  RUNNING: 'success',
  FAILED: 'error',
  PAUSING: 'info',
  STOPPED: 'warning',
};

export function updateRetakeInstance(instance: NonOwnedInstance): NonOwnedInstance {
  return { ...instance, performingRetake: true };
}

export function getInstanceStatus(instance: WbInstanceStatusProps): WbInstanceStatus {
  const status = instance.statusOverride ?? instance.status;
  if (status === 'PROVISIONED' && instance.nEcsTasks === 0) {
    return 'PAUSED';
  }

  return status;
}

export function isInstanceProvisioningWithFailures(instance: WbInstanceStatusProps): boolean {
  const status = getInstanceStatus(instance);
  return status === 'PROVISIONING' && (instance.consecutiveFailures ?? 0) > 0;
}

export function getEcsTaskState(instance: Pick<WbInstance, 'awsCurrentTaskStatus'>): WbEcsTaskState | null | undefined {
  return instance.awsCurrentTaskStatus;
}

export function isRunning(instance: WbInstanceStatusProps): boolean {
  const status = getInstanceStatus(instance);
  const ecsState = getEcsTaskState(instance);
  return status === 'PROVISIONED' && instance.nEcsTasks !== 0 && (!ecsState || ecsState === 'RUNNING');
}

export function isPaused(instance: WbInstance): boolean {
  const status = getInstanceStatus(instance);
  return status === 'PAUSED';
}

export function canResize(instance: WbInstance): boolean {
  return isRunning(instance) || isPaused(instance);
}

export function canUpdateInstance(instance: WbInstanceStatusProps & Pick<WbInstance, 'canUpdate'>): boolean {
  const status = getInstanceStatus(instance);
  return status !== 'PROVISIONING' && status !== 'PAUSED' && instance.canUpdate === true;
}

export function shouldRetryInstanceAction(instance: WbInstanceStatusProps): boolean {
  const status = getInstanceStatus(instance);
  return isInstanceProvisioningWithFailures(instance) || status === 'PROVISIONING_ERROR' || status === 'REMOVING_ERROR';
}

export function isBusy(instance: WbInstanceStatusProps): boolean {
  const status = getInstanceStatus(instance);
  const ecsState = getEcsTaskState(instance);

  const up =
    status === 'PROVISIONING' || status === 'RESTARTING' || status === 'RETURNING' || status === 'REMOVING' || status === 'PAUSING';
  const ecs = ecsState === 'PENDING';
  return up || ecs;
}

export function isFailed(instance: WbInstanceStatusProps): boolean {
  const status = getInstanceStatus(instance);
  return status === 'REMOVING_ERROR' || status === 'PROVISIONING_ERROR';
}

export function toTaskState(instance: WbInstance | OrgInstance): WbEcsTaskState {
  if (isFailed(instance)) {
    return 'STOPPED';
  }

  if (isBusy(instance)) {
    return 'PENDING';
  }

  if (isRunning(instance)) {
    return 'RUNNING';
  }

  return 'STOPPED';
}

export function getInstanceUrl(shortId: string): string {
  const domain = window.CONFIG.wbDomainTemplate.replace('_', shortId);
  return `https://${domain}`;
}
