import { v4 } from 'uuid';
import { ErrorNotification } from '../../../services/error-notification';
import { WbApi, WbProvisionRequest, WbScript } from '../workbench-api';
import { InstallPackages } from './install-packages';
import { initialInstanceSize, InstanceSize, instanceSizeProvisionRequest, isInstanceSizeValid } from './instance-size';
import { InstanceDailyScheduledPause, InstanceStalePause } from './instance-scheduled-pause';
import dayjs from 'dayjs';
import { UpdateInstanceLogo } from './instance-list';
import { arrayBufferToBase64 } from '../../../utils/utils';

export interface InstanceCreateModel {
  dialogId: string; // distinguishes two different instances of a dialog from each other
  name?: string;
  state: 'loading' | 'ready' | 'saving';
  availableScripts: WbScript[];
  selectedScripts: string[];
}

export async function loadInstanceCreate(
  api: WbApi,
  getModel: () => InstanceCreateModel | undefined,
  update: (
    a: InstanceCreateModel,
    b: InstallPackages,
    p: InstanceDailyScheduledPause,
    s: InstanceStalePause,
    c: InstanceSize,
    l: UpdateInstanceLogo
  ) => void
): Promise<void> {
  const dialogId = v4();

  update(
    {
      dialogId,
      name: undefined,
      state: 'loading',
      availableScripts: [],
      selectedScripts: [],
    },
    {
      packageSearchTerm: '',
      packageSearchResults: [],
      selectedPackages: [],
      isLoading: false,
    },
    {
      state: 'loading',
      dateError: undefined,
      instanceId: undefined,
      timezone: dayjs.tz.guess(),
      isPausedOnWeekends: false,
      fromTime: null,
      toTime: null,
    },
    {
      state: 'loading',
      id: '',
      scheduledDays: 4,
      alertsEnabled: false,
    },
    initialInstanceSize(undefined, 'loading', undefined),
    { shortId: undefined, logo: undefined, downloadUrl: undefined, state: 'loading' }
  );

  const availableVirtualCpusRes = await api.getAvailableVirtualCpus();
  const availableScripts = window.CONFIG.isMidasPeer ? [] : (await api.getInstanceScripts()) ?? [];
  if (getModel()?.dialogId !== dialogId) {
    return;
  }

  update(
    {
      dialogId: dialogId,
      name: undefined,
      state: 'ready',
      availableScripts,
      selectedScripts: [],
    },
    {
      packageSearchTerm: '',
      packageSearchResults: [],
      selectedPackages: [],
      isLoading: false,
    },
    {
      state: 'ready',
      dateError: undefined,
      instanceId: undefined,
      timezone: dayjs.tz.guess(),
      isPausedOnWeekends: false,
      fromTime: null,
      toTime: null,
    },
    {
      state: 'ready',
      id: '',
      scheduledDays: 4,
      alertsEnabled: false,
    },
    initialInstanceSize(undefined, 'ready', availableVirtualCpusRes?.availableVirtualCpus),
    { shortId: undefined, logo: undefined, downloadUrl: undefined, state: 'ready' }
  );
}

export function instanceCreateSelectScript(
  model: InstanceCreateModel | undefined,
  script: WbScript,
  selected: boolean
): InstanceCreateModel | undefined {
  if (!model) {
    return model;
  }

  const selectedScripts = selected ? [...model.selectedScripts, script.id] : model.selectedScripts.filter((id) => id !== script.id);
  return { ...model, selectedScripts };
}

export function instanceCreateNameChange(model: InstanceCreateModel | undefined, name: string): InstanceCreateModel | undefined {
  if (!model) {
    return model;
  }

  return { ...model, name: name };
}

export async function instanceCreateSave(
  api: WbApi,
  getModel: () => InstanceCreateModel | undefined,
  installModel: InstallPackages | undefined,
  dailyInstanceScheduledPause: InstanceDailyScheduledPause | undefined,
  staleInstancePause: InstanceStalePause | undefined,
  instanceSize: InstanceSize | undefined,
  updateInstanceLogo: UpdateInstanceLogo | undefined,
  refresh: () => Promise<void>,
  update: (
    x: InstanceCreateModel | undefined,
    y: InstallPackages | undefined,
    p: InstanceDailyScheduledPause | undefined,
    s: InstanceStalePause | undefined,
    z: InstanceSize | undefined,
    l: UpdateInstanceLogo | undefined
  ) => void
): Promise<ErrorNotification | undefined> {
  const model = getModel();

  if (!model || !instanceSize || !isInstanceSizeValid(instanceSize) || dailyInstanceScheduledPause?.dateError) {
    return;
  }

  update(
    { ...model, state: 'saving' },
    installModel,
    dailyInstanceScheduledPause,
    staleInstancePause,
    {
      ...instanceSize,
      state: 'saving',
    },
    updateInstanceLogo
  );

  const buffer = updateInstanceLogo?.logo ? await updateInstanceLogo.logo.arrayBuffer() : undefined;
  const logo = buffer ? arrayBufferToBase64(buffer) : undefined;

  const provisionParams: WbProvisionRequest = {
    name: model.name,
    scripts: model.selectedScripts,
    aptPackages: window.CONFIG.isMidasPeer ? [] : installModel?.selectedPackages ?? [],
    condaPackages: window.CONFIG.isMidasPeer ? installModel?.selectedPackages ?? [] : [],
    ...instanceSizeProvisionRequest(instanceSize),
    logo,
  };

  if (dailyInstanceScheduledPause?.fromTime && dailyInstanceScheduledPause?.toTime) {
    provisionParams.dailyScheduledPause = {
      timezone: dailyInstanceScheduledPause.timezone,
      fromTime: dailyInstanceScheduledPause.fromTime,
      toTime: dailyInstanceScheduledPause.toTime,
      isPausedOnWeekends: dailyInstanceScheduledPause.isPausedOnWeekends,
    };
  }

  if (staleInstancePause) {
    provisionParams.staleInstancePause = {
      scheduledPauseDays: staleInstancePause?.scheduledDays,
    };
  }

  const res = await api.provisionNew(provisionParams);
  const newModel = getModel();
  if (newModel?.dialogId !== model.dialogId) {
    return;
  }
  if (!res?.ok) {
    update(
      { ...newModel, state: 'ready' },
      installModel,
      dailyInstanceScheduledPause,
      staleInstancePause,
      {
        ...instanceSize,
        state: 'ready',
      },
      updateInstanceLogo
    );
    return { message: 'Error creating instance', description: res?.error };
  }

  try {
    await refresh();
  } finally {
    if (getModel()?.dialogId === model.dialogId) {
      update(undefined, undefined, undefined, undefined, undefined, undefined);
    }
  }
}
