import routes from './../utils/routes';
import { UserDataLakeApi } from './api/user-data-lake-api';
import { ISettingsConfigContext } from './context/settings-config-context';
import { createExtendedConfig, createHeader } from './pages/data-query/components';
import * as QueryTypes from './pages/data-query/components/query-types';
import { DataQueryFormItem } from './pages/types';

const createItem = (createdItem: DataQueryFormItem, stash: { updateAppConfig: ISettingsConfigContext['updateAppConfig'] }) => {
  try {
    const { updateAppConfig } = stash;

    updateAppConfig((existingConfig) => ({
      ...existingConfig,
      tabs: [...existingConfig.tabs!, createdItem],
    }));

    return createdItem;
  } catch (e) {
    console.error('[UiModules] Error adding new item...', e);

    return false;
  }
};

const MAX_DELETED_RESULTS = 10;
enum ROUTE_CONFIG_KEY_MAP {
  workflow = 'workflows',
}

const deleteItem = (
  deletedUuid: string,
  stash: { updateAppConfig: ISettingsConfigContext['updateAppConfig']; appConfig: ISettingsConfigContext['appConfig'] },
  routeType: string
) => {
  try {
    const routeConfigKey = ROUTE_CONFIG_KEY_MAP[routeType as keyof typeof ROUTE_CONFIG_KEY_MAP];
    const { updateAppConfig } = stash;

    updateAppConfig((existingConfig) => ({
      ...existingConfig,
      tabs: existingConfig.tabs!.filter(({ uuid }) => uuid !== deletedUuid),
      [routeConfigKey]: routeConfigKey && existingConfig[routeConfigKey]?.filter(({ uuid }) => uuid !== deletedUuid),
    }));

    return true;
  } catch (e) {
    console.error('[UiModules] Error deleting item...', e);

    return false;
  }
};

const limitedPreviousSearches = (config: DataQueryFormItem, idToDelete: string) => {
  const existingDeletedSearches =
    config.previousSearches!.length >= MAX_DELETED_RESULTS
      ? config.previousSearches!.slice(1, config.previousSearches!.length)
      : config.previousSearches!;

  return [...existingDeletedSearches, config.searchConfigs!.find(({ uuid }) => uuid === idToDelete)!];
};

const UIModules: {
  [route: string]: {
    createItem: (
      createdItem: DataQueryFormItem,
      stash: { updateAppConfig: ISettingsConfigContext['updateAppConfig'] }
    ) => false | DataQueryFormItem;
    deleteItem: (
      deletedUuid: string,
      stash: { updateAppConfig: ISettingsConfigContext['updateAppConfig']; appConfig: ISettingsConfigContext['appConfig'] },
      routeType: string
    ) => boolean | Promise<boolean>;
    serialiseItem: (a: unknown) => unknown;
    deserialiseItem: (a: unknown) => unknown;
  };
} = {
  [routes.Data]: {
    createItem: (generatedItem, stash) => {
      const { readOnly, ...createdItem } = generatedItem;

      try {
        const { updateAppConfig } = stash;
        const newItem = createHeader(
          createExtendedConfig({
            ...createdItem,
            config: {
              // TODO: set default query type back to Interval Query
              queryType: createdItem?.config?.queryType || QueryTypes.TicksQuery,
              ...createdItem.config,
            },
          })
        );

        updateAppConfig((existingConfig) => ({
          ...existingConfig,
          tabs: [
            ...existingConfig.tabs!,
            {
              ...createdItem,
              name: newItem.name,
            },
          ],
          searchConfigs: [
            ...existingConfig.searchConfigs!,
            {
              ...newItem,
              meta: {
                ...newItem?.meta,
                readOnly,
              },
            },
          ],
        }));

        return createdItem;
      } catch (e) {
        console.error('[UiModules] Error adding new item...', e);

        return false;
      }
    },
    deleteItem: (deletedUuid, stash) => {
      try {
        const { updateAppConfig } = stash;

        updateAppConfig((existingConfig) => ({
          ...existingConfig,
          tabs: existingConfig.tabs!.filter(({ uuid }) => uuid !== deletedUuid),
          searchConfigs: existingConfig.searchConfigs!.filter(({ uuid }) => uuid !== deletedUuid),
          previousSearches: limitedPreviousSearches(existingConfig, deletedUuid),
        }));

        return true;
      } catch (e) {
        console.error('[UiModules] Error deleting item...', e);

        return false;
      }
    },
    serialiseItem: (a) => a,
    deserialiseItem: (a) => a,
  },
  [routes.Workflow]: {
    createItem,
    deleteItem: async (deletedUuid, stash) => {
      const { appConfig } = stash;
      const isCreateUdtTab = appConfig.tabs?.find((tab) => tab.uuid === deletedUuid)?.name === 'WF: Create a User-Defined Table';
      if (isCreateUdtTab) {
        const userDataLakeApi = new UserDataLakeApi();
        const apiRes = await userDataLakeApi.deleteUploadedData({ workflowId: deletedUuid });
        if (!apiRes) {
          console.error('Unable to delete the uploaded data.');
          return false;
        }
      }

      return deleteItem(deletedUuid, stash, 'workflow');
    },
    serialiseItem: (a) => a,
    deserialiseItem: (a) => a,
  },
  [routes.DpState]: {
    createItem: (createdItem, stash) => {
      return createItem({ ...createdItem, name: 'My Data Activity' }, stash);
    },
    deleteItem,
    serialiseItem: (a) => a,
    deserialiseItem: (a) => a,
  },
};

export default UIModules;
