import { ErrorNotification } from '../../../services/error-notification';
import { showErrorNotification } from '../../../_shared/views/Notifications';
import { ModuleInstall } from '../../modules/models/module-install';
import { User } from '../../users/users-api';
import { ServicesApi, SharePermissions, WbServiceStatus } from '../services-api';
import { WbService } from './service';

export interface ConfirmServiceAction {
  state: 'ready' | 'saving';
  service: WbService;
}

export interface ConfirmShareServiceAction extends ConfirmServiceAction {
  type: 'just-me' | 'organization' | 'users';
  selectedUsersIds?: string[];
  allUsers?: User[];
}

export function initialConfirmServiceAction(service: WbService): ConfirmServiceAction {
  return { state: 'ready', service };
}

export function initialConfirmShareServiceAction(service: WbService, users: User[]): ConfirmShareServiceAction {
  const type =
    service.permissions?.type === 'users' && service.permissions.userSubjects.length === 0 ? 'just-me' : service.permissions?.type;
  const selectedUserSubjects = (service.permissions?.type === 'users' && service.permissions.userSubjects) || [];
  return {
    state: 'ready',
    service,
    type: type || 'just-me',
    selectedUsersIds: selectedUserSubjects,
    allUsers: users,
  };
}

export interface ServicesListModel {
  loading: boolean;
  services: WbService[];
  confirmStopService: ConfirmServiceAction | undefined;
  confirmShareService: ConfirmShareServiceAction | undefined;
  confirmRemoveService: ConfirmServiceAction | undefined;
  confirmUninstallService: ConfirmServiceAction | undefined;
  install: ModuleInstall | undefined;
}

export function initialAppList(): ServicesListModel {
  return {
    loading: true,
    services: [],
    confirmStopService: undefined,
    confirmShareService: undefined,
    confirmRemoveService: undefined,
    confirmUninstallService: undefined,
    install: undefined,
  };
}

export function updateServices(model: ServicesListModel, services: WbService[]): ServicesListModel {
  return { ...model, services, loading: false };
}

function updateStatusOverride(model: ServicesListModel, serviceId: string, status: WbServiceStatus | undefined): ServicesListModel {
  return { ...model, services: model.services.map((i) => (i.id === serviceId ? { ...i, statusOverride: status } : i)) };
}

export async function deployService(api: ServicesApi, model: ServicesListModel, serviceId: string): Promise<void> {
  const service = model.services.find((s) => s.id === serviceId);
  if (service) {
    updateStatusOverride(model, serviceId, 'PROVISIONING');
    const res = await api.deployService(service.shortId, service.id);
    if (!res?.ok) {
      showErrorNotification({ message: res?.error ?? 'Error deploying a service', deduplicationKey: 'error-deploying-service' });
    }
  }
}

function makeServiceAction(
  modelKey: 'confirmStopService' | 'confirmRemoveService' | 'confirmUninstallService',
  apiCall: (api: ServicesApi, app: WbService) => Promise<{ ok: boolean } | undefined>,
  tempState: WbServiceStatus,
  errorMessage: string
) {
  return async (
    api: ServicesApi,
    model: () => ServicesListModel,
    update: (_: ServicesListModel) => void,
    refresh: () => Promise<void>
  ): Promise<ErrorNotification | undefined> => {
    const confirmService = model()[modelKey];
    if (!confirmService) {
      return;
    }
    update({
      ...updateStatusOverride(model(), confirmService.service.id, tempState),
      [modelKey]: { ...confirmService, state: 'saving' },
    });

    const res = await apiCall(api, confirmService.service);
    if (!res?.ok) {
      return { message: errorMessage };
    }

    try {
      await refresh();
    } finally {
      const updatedModel = updateStatusOverride(model(), confirmService.service.id, undefined);
      updatedModel[modelKey] = undefined;
      update(updatedModel);
    }
  };
}

export const stopService = makeServiceAction(
  'confirmStopService',
  (api, service) => api.stopService(service.shortId, service.id),
  'STOPPING',
  'Error stopping app'
);

export function stopServiceCancelled(model: ServicesListModel): ServicesListModel {
  return { ...model, confirmStopService: undefined };
}

export const shareService = async (
  api: ServicesApi,
  model: () => ServicesListModel,
  update: (_: ServicesListModel) => void,
  refresh: () => Promise<void>
) => {
  const confirmService = model().confirmShareService;

  if (!confirmService) {
    return;
  }
  update({
    ...model(),
    confirmShareService: { ...confirmService, state: 'saving' },
  });

  let permissions: SharePermissions;
  switch (confirmService.type) {
    case 'just-me':
      permissions = { type: 'users', userSubjects: [] };
      break;
    case 'users':
      permissions = { type: 'users', userSubjects: confirmService.selectedUsersIds || [] };
      break;
    case 'organization':
      permissions = { type: 'organization' };
  }
  const res = await api.shareService(confirmService.service.shortId, confirmService.service.id, permissions);
  if (!res?.ok) {
    update({
      ...model(),
      confirmShareService: { ...confirmService, state: 'ready' },
    });
    return { message: 'Error sharing app' };
  }
  try {
    await refresh();
  } finally {
    const updatedModel = updateStatusOverride(model(), confirmService.service.id, undefined);
    updatedModel.confirmShareService = undefined;
    update(updatedModel);
  }
};

export function shareServiceCancelled(model: ServicesListModel): ServicesListModel {
  return { ...model, confirmShareService: undefined };
}

export const deleteService = makeServiceAction(
  'confirmRemoveService',
  (api, service) => api.deleteService(service.shortId, service.id),
  'REMOVING',
  'Error deleting app'
);

export function deleteServiceCancelled(model: ServicesListModel): ServicesListModel {
  return { ...model, confirmRemoveService: undefined };
}

export const uninstallService = makeServiceAction(
  'confirmUninstallService',
  (api, service) => api.uninstallService(service.id),
  'UNINSTALLING',
  'Error uninstalling app'
);

export function uninstallServiceCancelled(model: ServicesListModel): ServicesListModel {
  return { ...model, confirmUninstallService: undefined };
}

export function getServiceStatus(service: WbService): WbServiceStatus {
  return service.statusOverride ?? service.status;
}
