/*eslint prefer-spread: "off"*/

import { v4 as uuidv4 } from 'uuid';

import { isPromise } from '../utils';

export interface IWebViewIpcBase {
  startListening(): void;

  stopListening(): void;

  postMessage(message: any): void;

  createProxy<T extends Record<string, unknown>>(): T;
}

type CallbackRecord = {
  id: string;
  resolve: any;
  reject: any;
  createdAtMs: number; // DWT: not used right now, but we could have a timeout
};

type Handler = (...params: any) => void;
/**
 * A very simple JSON-message-based IPC system which is targetted at a WebView hosted HTML page invoking
 * functions exposed by an extension.
 */
export abstract class WebViewIpcBase implements IWebViewIpcBase {
  private pendingCallbacks: Map<string, CallbackRecord> = new Map<string, CallbackRecord>();

  private eventHandlers: Map<string, Handler[]> = new Map<string, Handler[]>();

  // eslint-disable-next-line @typescript-eslint/require-await
  protected async handleMessage(e: any) {
    const { type } = e;
    switch (type) {
      case 'mst.wb.ipc.res':
        this.fire(() => this.handleResponse(e));
        return;
      case 'mst.wb.ipc.req':
        // eslint-disable-next-line @typescript-eslint/no-misused-promises
        this.fire(() => this.handleRequest(e));
        return;
      case 'mst.wb.ipc.event':
        this.fire(() => this.handleCallback(e));
        return;
      // server has sent back data for us.
      default:
        console.warn('[mst.wb.ipc] unhandled message type...', type);
    }
  }

  constructor(
    protected target?: any,
    protected sessionId: string = ''
  ) {
    this.handleMessage = this.handleMessage.bind(this);
  }

  abstract fire(callback: () => void): void;

  abstract startListening(): void;

  abstract stopListening(): void;

  abstract postMessage(message: any): void;

  on(eventName: string, handler: () => void): () => void {
    if (this.eventHandlers.has(eventName)) {
      this.eventHandlers.get(eventName)!.push(handler);
    } else {
      this.eventHandlers.set(eventName, [handler]);
    }

    return () => {
      const collection = this.eventHandlers.get(eventName);
      if (!collection) {
        return;
      }

      const index = collection.indexOf(handler);
      collection.splice(index, 1);
    };
  }

  raiseEvent(actionId: string, payload?: any) {
    this.postMessage({
      type: 'mst.wb.ipc.event',
      sessionId: this.sessionId,
      actionId,
      payload,
    });
  }

  createProxy<T extends Record<string, unknown>>(): T {
    const handler = {
      set: () => {
        throw new Error('set prop not supported by proxy!');
      },
      get: (target: any, prop: any) => {
        if (prop === '__client') {
          return this;
        }
        // DWT: we have to assume the user is invoking a function here...
        return (...parameters: any[]) => this.invoke(prop, ...parameters);
      },
    };

    return new Proxy<T>(Object.create(null), handler);
  }

  public invoke(name: string, ...parameters: any[]): Promise<any> {
    const id = uuidv4();
    return new Promise((resolve, reject) => {
      const callbackRecord = <CallbackRecord>{
        id,
        resolve,
        reject,
        createdAtMs: new Date().getTime(),
      };

      this.pendingCallbacks.set(id, callbackRecord);

      this.postMessage({
        type: 'mst.wb.ipc.req',
        sessionId: this.sessionId,
        id,
        fn: name,
        parameters: parameters ? JSON.stringify(parameters) : undefined,
      });
    });
  }

  protected async invokeFunction(payload: { fn: string; parameters?: any; id: string }): Promise<{ error?: Error; result?: any } | null> {
    const { fn, parameters } = payload;

    try {
      const functionReference = this.target[fn];
      if (!functionReference) {
        console.warn('[mst.wb.ipc] failed to find function "%s" on target...', fn);
        return {
          error: new Error('Unknown function specified...'),
        };
      }

      const output = Reflect.apply(functionReference, this.target, parameters ? JSON.parse(parameters) : []);
      const resolvedOutput = isPromise(output) ? await output : output;
      return {
        result: resolvedOutput ? JSON.stringify(resolvedOutput) : null, // null, not undefined, so we __know__ it's none.
      };
    } catch (error: any) {
      const serialisableError = {
        name: error.name,
        message: error.message,
        stack: error.stack,
      };

      console.error('[mst.wb.ipc] function errored during execution!', serialisableError);
      return {
        error: serialisableError,
      };
    }
  }

  private async handleRequest(data: any): Promise<void> {
    const { sessionId, id } = data;

    if (sessionId !== this.sessionId) {
      console.warn('[mst.wb.ipc] session IDs do not match...', sessionId, this.sessionId);
      return;
    }

    const output = await this.invokeFunction(data);

    this.postMessage({
      type: 'mst.wb.ipc.res',
      sessionId,
      id,
      ...output,
    });
  }

  private handleResponse(data: any): void {
    const { id, sessionId, result, error } = data;

    if (sessionId !== this.sessionId) {
      console.warn('[mst.wb.ipc] session IDs do not match...', sessionId, this.sessionId);
      return;
    }

    const callbackRecord = this.pendingCallbacks.get(id);
    if (!callbackRecord) {
      console.warn('[mst.wb.ipc] failed to find callback by ID: ', id);
      return;
    }

    this.pendingCallbacks.delete(id);

    if (error) {
      console.info('[mst.wb.ipc] error returned from IPC operation...', error);
      callbackRecord.reject(error);
      return;
    }

    callbackRecord.resolve(result ? JSON.parse(result) : undefined);
  }

  private handleCallback(data: any) {
    const { id, sessionId, actionId, payload } = data;

    console.info('[mst.wb.ipc] event!', data);

    if (sessionId !== this.sessionId) {
      console.warn('[mst.wb.ipc] session IDs do not match...', sessionId, this.sessionId);
      return;
    }

    const eventHandlers = this.eventHandlers.get(actionId);
    if (!eventHandlers || eventHandlers.length === 0) {
      console.warn('[mst.wb.ipc] failed to find callback by ID: ', id);
      return;
    }

    eventHandlers.forEach((callback) => {
      try {
        callback.apply(null, payload);
      } catch (e) {
        console.error('[mst.wb.ipc] callback failed!', e);
      }
    });
  }
}
