import isEqual from 'lodash/isEqual';

// not really a delegate if it has a field.
export interface PeriodicRequestDelegate<T> {
  interval: number;
  /**
   * Always invoke the 'onPeriodicRequestResult' callback, regardless of whether or not
   * data from the server is different from the previous result.
   */
  neverCheckEquality?: boolean;

  onPeriodicRequest(): Promise<T>;

  onPeriodicRequestResult(value: T): void;
}

export class PeriodicRequest<T> {
  private requestCounter = 0;

  private lastAppliedRequest = 0;

  private lastResponse: T | undefined;

  constructor(private delegate: PeriodicRequestDelegate<T>) {}

  stop: () => void = () => {};

  start(immediately = true) {
    // counter to force periodic requests to be in-order (out-of-order calls are discarded)

    const token = setInterval(() => void this.refresh(), this.delegate.interval);
    if (immediately) {
      void this.refresh();
    }

    this.stop = () => clearInterval(token);

    return this.stop;
  }

  refresh = async (): Promise<void> => {
    const requestNumber = ++this.requestCounter;

    const response = await this.delegate.onPeriodicRequest();

    if (this.lastAppliedRequest > requestNumber) {
      // more recent data has already been applied, discard this call
      return;
    }

    this.lastAppliedRequest = requestNumber;

    const shouldIgnore = !this.delegate.neverCheckEquality && isEqual(this.lastResponse, response);
    if (shouldIgnore) {
      return;
    }

    this.lastResponse = response;
    this.delegate.onPeriodicRequestResult(response);
  };
}
