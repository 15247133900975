import { ColumnFilterItem } from 'antd/lib/table/interface';
import dayjs from 'dayjs';

export const defaultSort = (a: number, b: number) => a - b;

export const createSorter = (dataIndex: number, isDate: boolean) => (a: number[], b: number[]) => {
  if (isDate && dayjs(a[dataIndex]).isValid()) {
    return dayjs(a[dataIndex]).diff(b[dataIndex]);
  }

  return defaultSort(a[dataIndex], b[dataIndex]);
};

export const createNumericSorter =
  <T extends string>(dataIndex: T) =>
  (a: Record<T, number>, b: Record<T, number>) =>
    a[dataIndex] - b[dataIndex];

export const createAlphabeticSorter =
  <T extends string>(dataIndex: T) =>
  (a: Record<T, string | undefined>, b: Record<T, string | undefined>) => {
    if (!a[dataIndex] || !b[dataIndex]) {
      return 1;
    }
    return a[dataIndex]!.localeCompare(b[dataIndex]!);
  };

export const createDateStringSorter =
  <T extends string>(dataIndex: T) =>
  (a: Record<T, string | undefined>, b: Record<T, string | undefined>) => {
    if (!a[dataIndex] || !b[dataIndex]) {
      return 1;
    }
    return new Date(a[dataIndex]!).getTime() - new Date(b[dataIndex]!).getTime();
  };

export const getTableColumnFilters = <T extends Record<string, any>>(data: T[], dataIndex: keyof T): ColumnFilterItem[] => {
  const filterValues = new Set<any>();

  data.forEach((d) => {
    const value = d[dataIndex];
    if (typeof value === 'boolean') {
      filterValues.add(value + '');
    } else if (value) {
      if (typeof value === 'string') {
        filterValues.add(value.trim());
      } else {
        filterValues.add(value);
      }
    }
  });

  return [...filterValues].sort().map((fV) => ({ text: fV, value: fV }));
};

export const getOnFilterHandler = <T>(dataIndex: keyof T) => {
  return (value: boolean | React.Key, record: T) => record[dataIndex] + '' === value.toString();
};
