import React, { useCallback } from 'react';
import { Link } from 'react-router-dom';
import { Button, MenuProps } from 'antd';
import { DashboardOutlined, DeleteOutlined, PlusOutlined } from '@ant-design/icons';
import { DashboardData } from '../dashboard-api';
import { DashboardList } from '../models/dashboard-list';
import { DashboardUpsertDialog, DashboardUpsertDialogDelegate } from './DashboardUpsertDialog';
import { DashboardRemoveDialog, DashboardRemoveDialogDelegate } from './DashboardRemoveDialog';
import { DASHBOARDS_ITEM_KEY, DASHBOARDS_ITEM_KEY_PREFIX } from '../../../side-nav/SideNav';

import styles from '../../../side-nav/SideNav.shared.module.css';

export interface DashboardNavDelegate extends DashboardUpsertDialogDelegate, DashboardRemoveDialogDelegate {
  onDashboardCreate(): void;

  onDashboardRemove(id: string): void;
}

function DashboardNavItem({ model, delegate }: { model: DashboardData; delegate: DashboardNavDelegate }) {
  const onRemove = useCallback(
    (evt: React.MouseEvent<HTMLElement>) => {
      evt.stopPropagation();
      delegate.onDashboardRemove(model.id);
    },
    [model.id, delegate]
  );

  return (
    <div className={styles.navLabel}>
      <Link to={`/dashboard/${model.id}`}>{model.name}</Link>
      <Button icon={<DeleteOutlined />} type="text" danger onClick={onRemove} />
    </div>
  );
}

function DashboardNavMainLabel({ model, delegate }: { model: DashboardList; delegate: DashboardNavDelegate }) {
  const stopPropagation = useCallback((evt: React.KeyboardEvent<HTMLElement> | React.MouseEvent<HTMLElement>) => evt.stopPropagation(), []);
  const onCreate = useCallback(
    (evt: React.MouseEvent<HTMLElement>) => {
      const isDashboardExpanded = model.openKeys.includes(DASHBOARDS_ITEM_KEY);
      if (isDashboardExpanded) {
        evt.stopPropagation();
        evt.preventDefault();
      }

      delegate.onDashboardCreate();
    },
    [delegate, model]
  );

  return (
    <div data-testid="side-nav-dashboards" className="sidebar-nav-item-title-icon-container">
      <div>Dashboards</div>
      <Button icon={<PlusOutlined />} type="text" onClick={onCreate} />

      {/* See https://github.com/jsx-eslint/eslint-plugin-jsx-a11y/blob/3d1d26d89d492947cbf69f439deec9e7cfaf9867/docs/rules/no-static-element-interactions.md#case-the-event-handler-is-only-being-used-to-capture-bubbled-events */}
      {/*eslint-disable-next-line jsx-a11y/no-static-element-interactions*/}
      <div onKeyDown={stopPropagation} onClick={stopPropagation}>
        {model.create && <DashboardUpsertDialog model={model.create} delegate={delegate} />}
        {model.remove && <DashboardRemoveDialog model={model.remove} delegate={delegate} />}
      </div>
    </div>
  );
}

function getDashboardNavItems(model: DashboardList, delegate: DashboardNavDelegate) {
  return model.dashboards.length > 0
    ? model.dashboards.map((d) => ({
        icon: <DashboardOutlined />,
        label: <DashboardNavItem model={d} delegate={delegate} />,
        key: `${DASHBOARDS_ITEM_KEY_PREFIX}${d.id}`,
      }))
    : [
        {
          label: model.state === 'loading' ? 'Loading…' : 'No dashboards to display',
          key: `${DASHBOARDS_ITEM_KEY_PREFIX}empty`,
          disabled: true,
        },
      ];
}

export function getDashboardNavMain({
  model,
  delegate,
}: {
  model: DashboardList;
  delegate: DashboardNavDelegate;
}): Required<MenuProps>['items'][number] {
  return {
    label: <DashboardNavMainLabel model={model} delegate={delegate} />,
    key: DASHBOARDS_ITEM_KEY,
    icon: <DashboardOutlined />,
    children: getDashboardNavItems(model, delegate),
  };
}
