/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/no-redundant-type-constituents */
import { WebViewIpcServerIFrame } from '@mst-fe/frontend-shared';

import { createWorkspaceRicChangeEvent, extractChange } from './wow-context-events';

export interface ColourChannel {
  background: string;
  channelId: number;
}

export interface ContextLinkingResponse {
  groupId: number;
}

export interface ContextData {
  [key: string]: any;
}

export interface InteropSubscriptionResult {
  unsubscribe(): void;
}

export interface LinkedItemChangedPayload {
  FieldList: string[];
  IdentifierList: string[];
  ParameterList: string[] | string | undefined;
  RIC: string | string[] | undefined;
  SelectionNS: string | undefined;
  dataSource: string | undefined;
  fields: object | undefined;
}

export interface IContextPlugin {
  getAsync(): Promise<ContextData[] | undefined>;

  change(data: ContextData[]): void;

  onChange(handler: (data: any) => void): InteropSubscriptionResult;

  onLinked(handler: (data: ContextLinkingResponse) => void): void;

  onUnlinked(handler: (data: ContextLinkingResponse) => void): void;

  joinColorChannel(channelId: number): void;

  leaveColorChannel(channelId: number): void;

  getColorChannels(): Promise<ColourChannel[]>;
}

export class WowContextBridge {
  private ipcServer: WebViewIpcServerIFrame;
  private channels: ColourChannel[] | undefined;

  private ipcClientInvoker = new (class {
    constructor(private bridge: WowContextBridge) {}

    symbolChanged(symbol: string) {
      const payload = createWorkspaceRicChangeEvent(symbol);
      this.bridge.context.change([payload]);
    }
  })(this);

  // for testing, the user can provide their own mock of the ipcServer.
  constructor(
    protected context: IContextPlugin,
    targetWindow: Window,
    ipcServer?: WebViewIpcServerIFrame | undefined
  ) {
    this.ipcServer = ipcServer || new WebViewIpcServerIFrame(targetWindow, this.ipcClientInvoker);
    this.ipcServer.startListening();
  }

  async initialise(): Promise<void> {
    const channels = await this.context.getColorChannels();
    console.info('[wow] found channels: ', channels);
    this.channels = channels;

    this.context.onChange(this.handleData.bind(this));
    this.context.onLinked(this.handleLinked.bind(this));
    this.context.onUnlinked(this.handleUnlinked.bind(this));
  }

  getColourChannels(): Promise<ColourChannel[]> {
    return this.context.getColorChannels();
  }

  joinColourChannel(channelId: number) {
    this.context.joinColorChannel(channelId);
  }

  leaveColourChannel(channelId: number) {
    this.context.leaveColorChannel(channelId);
  }

  // the actual, documented definition of this signature is "string".
  // in reality, any known type of object is passed to this function.
  public handleData(data: any) {
    console.info('[wow] got data!', data);

    // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
    const ric = extractChange(data, 'RIC');
    if (ric) {
      this.ipcServer.raiseEvent('workspaceRicChanged', { ric });
      return;
    }
  }

  private handleLinked(data: ContextLinkingResponse) {
    console.info('[wow] linked! ', data);
  }

  private handleUnlinked(data: ContextLinkingResponse) {
    console.info('[wow] unlinked! ', data);
  }

  dispose() {
    this.ipcServer.raiseEvent('wowClientShutdown');
    this.ipcServer.stopListening();
  }
}
