import React, { useCallback, useContext, useMemo } from 'react';
import type { MenuProps } from 'antd';
import { Button, Dropdown } from 'antd';
import { KeyOutlined, LogoutOutlined, MailOutlined, SettingOutlined, UserOutlined } from '@ant-design/icons';

import { ArrowLeftIcon, ArrowRightIcon, MoonIcon, SunIcon, UserIcon } from '@heroicons/react/24/outline';
import { ItemType } from 'antd/es/menu/interface';

import { AppThemeContext } from '../../../hooks/use-theme';

import { SidebarButtons } from '../../../secondary-sidebar/controls/sidebar-buttons/SidebarButtons';

import {
  ADD_GITLAB_API_KEY,
  API_GEN_ITEM_KEY,
  EMAIL_ITEM_KEY,
  LOGOUT_ITEM_KEY,
  USER_PREFERENCES_KEY,
  UserInfoModel,
  USERNAME_ITEM_KEY,
} from '../models/user-info';
import { GenerateApiKeyDelegate } from './GenerateApiKeyDialog';
import { AddGitlabApiKeyDelegate } from './AddGitlabApiKeyDialog';
import { UserPreferencesDelegate } from './UserPreferencesDialog';

export interface UserInfoDelegate extends GenerateApiKeyDelegate, AddGitlabApiKeyDelegate, UserPreferencesDelegate {}

export function UserInfo({
  model,
  delegate,
  signOut,
  onSidebarCollapsed,
}: {
  model: UserInfoModel;
  delegate: UserInfoDelegate;
  signOut: () => void;
  onSidebarCollapsed?: (collapsed: boolean) => void;
}) {
  const { user, sidebarCollapsed } = model;
  const { theme, toggleTheme } = useContext(AppThemeContext);
  const handleSidebarCollapseToggle = useCallback(() => onSidebarCollapsed?.(!sidebarCollapsed), [sidebarCollapsed, onSidebarCollapsed]);

  const handleDropDownMenuItemClick: MenuProps['onClick'] = (menuInfo) => {
    switch (menuInfo.key) {
      case LOGOUT_ITEM_KEY:
        signOut();
        break;
      case API_GEN_ITEM_KEY:
        delegate.onStartGenerateApiKey();
        break;
      case ADD_GITLAB_API_KEY:
        delegate.onStartAddGitlabApiKey();
        break;
      case USER_PREFERENCES_KEY:
        delegate.onOpenUserPreferences();
        break;
      default:
        break;
    }
  };

  const menuItems: ItemType[] = useMemo(
    () => [
      {
        key: USERNAME_ITEM_KEY,
        icon: <UserOutlined />,
        label: `${user?.attributes?.name ?? 'No Name'}`,
        disabled: true,
      },
      {
        key: EMAIL_ITEM_KEY,
        icon: <MailOutlined />,
        label: `${user?.attributes?.email ?? 'No Email'}`,
        disabled: true,
      },
      model.workbenchAllowed
        ? {
            type: 'divider',
          }
        : null,
      model.workbenchAllowed
        ? {
            key: API_GEN_ITEM_KEY,
            icon: <KeyOutlined />,
            label: 'Generate API Key',
          }
        : null,
      model.isMidasPeer
        ? {
            key: ADD_GITLAB_API_KEY,
            icon: <KeyOutlined />,
            label: 'GitLab API Key',
          }
        : null,
      {
        type: 'divider',
      },
      model.workbenchAllowed
        ? {
            key: USER_PREFERENCES_KEY,
            icon: <SettingOutlined />,
            label: 'Preferences',
          }
        : null,
      model.workbenchAllowed
        ? {
            type: 'divider',
          }
        : null,
      {
        key: LOGOUT_ITEM_KEY,
        icon: <LogoutOutlined />,
        label: 'Log out',
      },
    ],
    [user?.attributes?.email, user?.attributes?.name, model.isMidasPeer, model.workbenchAllowed]
  );

  return (
    <SidebarButtons sidebarCollapsed={sidebarCollapsed}>
      {!sidebarCollapsed && (
        <>
          <Button
            type="text"
            onClick={toggleTheme}
            icon={theme === 'light' ? <MoonIcon height={16} width={16} /> : <SunIcon height={18} width={18} />}
            aria-label="Toggle theme"
          />
          <Dropdown menu={{ items: menuItems, onClick: handleDropDownMenuItemClick }} placement="bottomLeft" trigger={['click']}>
            <Button type="text" icon={<UserIcon height={18} width={18} />} aria-label="Open user account dropdown" />
          </Dropdown>
        </>
      )}
      <Button
        type="text"
        onClick={handleSidebarCollapseToggle}
        icon={sidebarCollapsed ? <ArrowRightIcon height={18} width={18} /> : <ArrowLeftIcon height={18} width={18} />}
        aria-label={sidebarCollapsed ? 'Expand sidebar' : 'Collapse sidebar'}
      />
    </SidebarButtons>
  );
}
