import { NavigateFunction } from 'react-router-dom';

import { getInstanceUrl } from '../../../../pages/shared/instance-mapping';
import { openUrl } from '../../../../services/windows';

import { NotificationItem } from '../models/notification';

export function handleReferencedEntityItem(item: NotificationItem, { navigate }: { navigate: NavigateFunction }) {
  const { referencedEntity } = item;
  if (!referencedEntity) {
    return;
  }

  const url = new URL(referencedEntity);
  const { pathname, protocol } = url;

  const path = pathname.substring(2);

  switch (protocol) {
    case 'open-workbench:':
      if (!path || undefined === 'undefined') {
        return;
      }

      openUrl(getInstanceUrl(path));
      break;
    case 'navigate-url:':
      navigate(path);
      break;
  }
}
