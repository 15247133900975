import { PeriodicRequest } from '../../services/periodic-request';

import { VERY_LONG_POLLING_INTERVAL } from '../../constants';

import {
  DataLakeMonitorStore,
  initialDataLakeMonitorStore,
  setDataLakeStoreLoading,
  updateDataLakeMonitorStore,
  updateDataLakeSelectedTables,
} from './models/data-lake-monitor-store';

import { DataLakeMonitorViewDelegate } from './views/DataLakeStatusMonitorView';

import { DataLakeStatus, MonitoringApi } from './monitoring-api';

export const fixTableNames = (input: DataLakeStatus | undefined): DataLakeStatus | undefined => {
  if (!input) {
    return input;
  }

  return {
    ...input,
    status: input.status.map((row) => ({
      ...row,
      table: row.table.split('.').pop() || row.table,
    })),
  };
};

export class DataLakeMonitorController implements DataLakeMonitorViewDelegate {
  private api = new MonitoringApi();

  private model = initialDataLakeMonitorStore();

  private getModel = (): DataLakeMonitorStore => this.model;

  private servicesPeriodicRequest: PeriodicRequest<DataLakeStatus | undefined>;

  private disposeCallbacks: (() => void)[] = [];

  constructor(private updateViewState: (_: DataLakeMonitorStore) => void) {
    this.servicesPeriodicRequest = new PeriodicRequest({
      interval: VERY_LONG_POLLING_INTERVAL,
      neverCheckEquality: true,

      onPeriodicRequest: async (): Promise<DataLakeStatus | undefined> => {
        this.update(setDataLakeStoreLoading(this.model));
        return fixTableNames(await this.api.getDataLakeStatus());
      },

      onPeriodicRequestResult: (result: DataLakeStatus) => {
        if (result !== undefined) {
          this.update(updateDataLakeMonitorStore(this.model, result));
        }
      },
    });

    this.disposeCallbacks.push(this.servicesPeriodicRequest.start(true));
  }

  async reloadDataLakeStatus(): Promise<void> {
    await this.api.refreshDataLakeStatus();
  }

  async runRefresh() {
    this.update(setDataLakeStoreLoading(this.model));

    const result = await this.api.getDataLakeStatus();
    if (!result) {
      return;
    }

    this.update(updateDataLakeMonitorStore(this.model, result));
  }

  selectTables(tables: string[]) {
    this.update(updateDataLakeSelectedTables(this.model, tables));
  }

  private update = (model: DataLakeMonitorStore) => {
    this.model = model;
    this.updateViewState(model);
  };

  dispose() {
    for (const cb of this.disposeCallbacks) {
      cb();
    }
    this.disposeCallbacks = [];
  }
}
