import debounce from 'lodash/debounce';
import dayjs, { Dayjs } from 'dayjs';
import duration from 'dayjs/plugin/duration';
import { RcFile } from 'antd/es/upload';
import { handleError } from '../../services/error-notification';
import { PeriodicRequest } from '../../services/periodic-request';
import { InstanceUpdateNotification, UserAssignmentSpecifier, WbApi, WbScript } from './workbench-api';
import { UsersApi } from '../users/users-api';
import { installPackagesSearch, installPackagesSetSelected } from './models/install-packages';
import { loadInstances, NonOwnedInstance, WbInstance } from './models/instance';
import {
  instanceCreateNameChange,
  instanceCreateSave,
  instanceCreateSelectScript,
  loadInstanceCreate,
} from './models/instance-create-model';
import { installPackages, loadInstallPackages } from './models/instance-install-packages';
import {
  asDailyScheduleUpdate,
  asInstallPackagesUpdate,
  asInstanceCreateUpdate,
  asInstanceDailyScheduleUpdate,
  asInstanceInstallPackages,
  asInstanceInstallPackagesUpdate,
  asInstanceReassignUpdate,
  asInstanceResizeUpdate,
  asInstanceSizeUpdate,
  asInstanceUpdateLogo,
  asInstanceUpgradeUpdate,
  asUpgradeAllInstancesUpdate,
  clearInstanceStorage,
  closeDailyInstancePauseModal,
  closeStaleInstancePauseModal,
  dailyInstanceScheduledPause,
  dailyInstanceScheduledPauseRemove,
  deleteInstance,
  deleteInstanceCancelled,
  initialInstanceList,
  InstanceListModel,
  openStaleInstancePauseModal,
  pauseInstance,
  renameInstance,
  resizeInstance,
  resizeInstanceCancelled,
  restartInstance,
  restartInstanceCancelled,
  resumeInstance,
  setInstanceIsOpen,
  staleInstancePauseConfig,
  startRenameInstance,
  stopRenameInstance,
  updateDailyInstancePause,
  updateInstanceRename,
  updateInstances,
  updateStaleInstancePause,
  updateTakingOwnershipInProgress,
  upgradeAllInstancesCancelled,
  upgradeInstance,
  upgradeInstanceCancelled,
  upgradeInstanceSilently,
  updateInstanceLogo,
  deleteInstanceLogo,
  loadInitialResizeInstance,
} from './models/instance-list';
import { instanceReassignSave, instanceReassignSetUser, loadInstanceReassign } from './models/instance-reassign';
import { InstanceListDelegate } from './views/InstanceList';
import {
  hideInstanceUpdateNotifications,
  InstanceUpdateNotificationsDelegate,
  showInstanceUpdateNotifications,
} from './views/InstanceUpdateNotifications';
import { AddPackagesControlDelegate } from './views/AddPackagesControl';
import { getUserSubject } from '../../services/auth';
import { createLoadingInstanceRemove } from './models/instance-remove';
import { updateInstanceSizeCpu, updateInstanceSizeDisk, updateInstanceSizeMem } from './models/instance-size';
import { UpgradeInstanceDialogDelegate } from './views/UpgradeInstanceDialog';
import { UpgradeAllInstancesDialogDelegate } from './views/UpgradeAllInstancesDialog';
import { canUpdateInstance, getInstanceStatus, getInstanceUrl, isInstanceProvisioningWithFailures } from '../shared/instance-mapping';
import { openUrl } from '../../services/windows';
import { ResizeInstanceDialogDelegate } from './views/ResizeInstanceDialog';
import { showErrorNotification } from '../../_shared/views/Notifications';
import { loadDailyScheduledPause } from './models/instance-scheduled-pause';
import { DailyScheduleInstancePauseDialogDelegate } from './views/DailyScheduleInstancePauseDialog';
import { LONG_POLLING_INTERVAL } from '../../constants';
import { LogoUploaderDialogDelegate } from './views/LogoUploaderDialog';

export class InstancesController
  implements
    InstanceListDelegate,
    InstanceUpdateNotificationsDelegate,
    AddPackagesControlDelegate,
    DailyScheduleInstancePauseDialogDelegate,
    LogoUploaderDialogDelegate
{
  private api = new WbApi();

  private usersApi = new UsersApi();

  private model = initialInstanceList();

  readonly confirmUpgradeDelegate: UpgradeInstanceDialogDelegate;

  readonly confirmUpgradeAllInstancesDelegate: UpgradeAllInstancesDialogDelegate;

  readonly confirmResizeDelegate: ResizeInstanceDialogDelegate;

  private instancesPeriodicRequest: PeriodicRequest<unknown>;

  private disposeCallbacks: (() => void)[] = [];

  constructor(private updateViewState: (_: InstanceListModel) => void) {
    this.confirmUpgradeDelegate = {
      onUpgradeInstanceSelected: async (upgrade: boolean) => {
        if (upgrade) {
          handleError(await upgradeInstance(this.api, this.getModel, this.update, this.instancesPeriodicRequest.refresh));
        } else {
          this.update(upgradeInstanceCancelled(this.model));
        }
      },
    };

    this.confirmUpgradeAllInstancesDelegate = {
      onUpgradeAllInstancesSelected: async (upgrade: boolean) => {
        if (!upgrade) {
          this.update(upgradeAllInstancesCancelled(this.model));
          return;
        }

        const model = this.getModel();

        this.update({
          ...model,
          confirmUpgradeAllInstances: {
            ...model.confirmUpgradeAllInstances!,
            state: 'saving',
          },
        });

        const eligibleInstances = this.getModel().instances.filter((instance) => canUpdateInstance(instance));

        const promises = Promise.all(
          eligibleInstances.map(
            async (model) =>
              await upgradeInstanceSilently(model.id, this.api, this.getModel, this.update, this.instancesPeriodicRequest.refresh)
          )
        );

        this.update(upgradeAllInstancesCancelled(this.model));

        const errors = (await promises).filter((i) => typeof i === 'string').join('\n');
        if (errors) {
          showErrorNotification({
            message: 'Some instances failed to update...',
            description: errors,
            deduplicationKey: 'MULTI_INSTANCE_UPDATE',
          });
        }
      },
    };

    this.confirmResizeDelegate = {
      onResizeInstanceSelected: async (upgrade) => {
        if (upgrade) {
          handleError(await resizeInstance(this.api, this.getModel, this.update, this.instancesPeriodicRequest.refresh));
        } else {
          this.update(resizeInstanceCancelled(this.model));
        }
      },

      onEditInstanceSizeCpu: (value) => {
        if (this.model.confirmResizeInstance) {
          this.updateResizeInstance(updateInstanceSizeCpu(this.model.confirmResizeInstance, value));
        }
      },

      onEditInstanceSizeMemory: (value) => {
        if (this.model.confirmResizeInstance) {
          this.updateResizeInstance(updateInstanceSizeMem(this.model.confirmResizeInstance, value));
        }
      },

      onEditInstanceSizeDisk: (value) => {
        if (this.model.confirmResizeInstance) {
          this.updateResizeInstance(updateInstanceSizeDisk(this.model.confirmResizeInstance, value));
        }
      },
    };

    this.instancesPeriodicRequest = new PeriodicRequest<
      { instances: WbInstance[]; instancesOwnedByOthers: NonOwnedInstance[] } | undefined
    >({
      interval: LONG_POLLING_INTERVAL,

      onPeriodicRequest: async () => await loadInstances(this.api, () => this.model.instances),

      onPeriodicRequestResult: (value) => {
        if (value !== undefined) {
          this.update(updateInstances(this.model, value.instances, value.instancesOwnedByOthers));
        }
      },
    });
    this.disposeCallbacks.push(this.instancesPeriodicRequest.start());
  }

  private getModel = (): InstanceListModel => this.model;

  private update = (model: InstanceListModel) => {
    this.model = model;
    this.updateViewState(model);
  };

  private getCreateInstance = () => this.model.createInstance;

  private updateInstanceCreate = asInstanceCreateUpdate(this.getModel, this.update);

  private updateInstanceSize = asInstanceSizeUpdate(this.getModel, this.update);

  //only for separate installation dialog
  private updateInstallPackages = asInstallPackagesUpdate(this.getModel, this.update);

  private updateUpgradeInstance = asInstanceUpgradeUpdate(this.getModel, this.update);

  private updateUpgradeAllInstances = asUpgradeAllInstancesUpdate(this.getModel, this.update);

  private updateResizeInstance = asInstanceResizeUpdate(this.getModel, this.update);

  private updateLogoInstance = asInstanceUpdateLogo(this.getModel, this.update);

  private updateInstanceReassign = asInstanceReassignUpdate(this.getModel, this.update);

  //both for separate installation dialog and the one integrated in wb creation dialog
  private updateInstanceInstallPackages = asInstanceInstallPackagesUpdate(this.getModel, this.update);

  //only for separate installation dialog
  private updateDailyScheduledPause = asDailyScheduleUpdate(this.getModel, this.update);

  //both for separate installation dialog and the one integrated in wb creation dialog
  private updateInstanceDailyScheduledPause = asInstanceDailyScheduleUpdate(this.getModel, this.update);

  dispose() {
    for (const cb of this.disposeCallbacks) {
      cb();
    }
    this.disposeCallbacks = [];
  }

  async onInstanceListOpened(): Promise<void> {
    const notifications = await this.api.getUpdateNotifications();
    if (notifications) {
      showInstanceUpdateNotifications({ model: notifications, delegate: this });
    }
  }

  onInstanceUpdateNotificationUpdate(notification: InstanceUpdateNotification): void {
    hideInstanceUpdateNotifications({ model: notification });

    const instance = this.model.instances.find((i) => i.id === notification.instanceId);
    if (instance) {
      void this.onUpdateInstance(instance);
    }
  }

  onInstanceUpdateNotificationCancel(notification: InstanceUpdateNotification): void {
    hideInstanceUpdateNotifications({ model: notification });
  }

  onInstanceUpdateNotificationIgnore(notification: InstanceUpdateNotification): void {
    hideInstanceUpdateNotifications({ model: notification });
    void this.api.ignoreUpdateNotification(notification.shortId); // ignore any eventual errors
  }

  async onStartCreateInstance(): Promise<void> {
    void loadInstanceCreate(this.api, this.getCreateInstance, this.updateInstanceCreate);
  }

  onInstanceCreateSelectScript(item: WbScript, selected: boolean): void {
    this.updateInstanceCreate(
      instanceCreateSelectScript(this.model.createInstance, item, selected),
      this.model.installPackages,
      this.model.dailyScheduledPause,
      this.model.staleInstancePause,
      this.model.instanceSize,
      this.model.updateInstanceLogo
    );
  }

  async onDoCreateInstance(): Promise<void> {
    handleError(
      await instanceCreateSave(
        this.api,
        this.getCreateInstance,
        this.model.installPackages,
        this.model.dailyScheduledPause,
        this.model.staleInstancePause,
        this.model.instanceSize,
        this.model.updateInstanceLogo,
        this.instancesPeriodicRequest.refresh,
        this.updateInstanceCreate
      )
    );
  }

  onInstanceNameChange(name?: string): void {
    if (this.model.createInstance) {
      this.updateInstanceCreate(
        instanceCreateNameChange(this.model.createInstance, name ?? ''),
        this.model.installPackages,
        this.model.dailyScheduledPause,
        this.model.staleInstancePause,
        this.model.instanceSize,
        this.model.updateInstanceLogo
      );
    }
  }

  async onCancelCreateInstance(): Promise<void> {
    this.updateInstanceCreate(undefined, undefined, undefined, undefined, undefined, undefined);
  }

  onEditInstanceSizeCpu(value: number): void {
    if (this.model.instanceSize) {
      this.updateInstanceSize(updateInstanceSizeCpu(this.model.instanceSize, value));
    }
  }

  onEditInstanceSizeMemory(value: string): void {
    if (this.model.instanceSize) {
      this.updateInstanceSize(updateInstanceSizeMem(this.model.instanceSize, value));
    }
  }

  onEditInstanceSizeDisk(value: string): void {
    if (this.model.instanceSize) {
      this.updateInstanceSize(updateInstanceSizeDisk(this.model.instanceSize, value));
    }
  }

  onPackageSearch = debounce(async (term: string): Promise<void> => {
    void installPackagesSearch(this.api, () => this.model.installPackages, this.updateInstallPackages, term);
  }, 800);

  onPackagesSelected(packages: string[]): void {
    this.updateInstallPackages(installPackagesSetSelected(this.model.installPackages, packages));
  }

  onSetPackageSearchLoading(value: boolean): void {
    this.updateInstallPackages({ ...this.model.installPackages!, isLoading: value });
  }

  async onStartInstance(instance: WbInstance): Promise<void> {
    openUrl(getInstanceUrl(instance.shortId));
  }

  async onDeleteInstance(instance: WbInstance): Promise<void> {
    this.update(
      createLoadingInstanceRemove(this.getModel, instance, this.api.getWorkbenchStoredFileSummary.bind(this), this.update, handleError)
    );
  }

  async onRemoveInstanceSelected(remove: boolean): Promise<void> {
    if (remove) {
      handleError(await deleteInstance(this.api, this.getModel, this.update, this.instancesPeriodicRequest.refresh));
    } else {
      this.update(deleteInstanceCancelled(this.model));
    }
  }

  async onRetryInstanceAction(instance: WbInstance): Promise<void> {
    const status = getInstanceStatus(instance);

    if (isInstanceProvisioningWithFailures(instance) || status === 'PROVISIONING_ERROR' || status === 'PAUSED') {
      await this.onUpdateInstance(instance);
    } else if (status === 'REMOVING_ERROR') {
      await this.onDeleteInstance(instance);
    }
  }

  async onUpdateInstance(instance: WbInstance): Promise<void> {
    void this.updateUpgradeInstance({
      id: instance.id,
      state: 'ready',
    });
  }

  async onResizeInstance(instance: WbInstance): Promise<void> {
    await loadInitialResizeInstance(this.api, instance, this.updateResizeInstance);
  }

  onUpdateLogo(instance: WbInstance) {
    void this.updateLogoInstance({ shortId: instance.shortId, logo: undefined, state: 'ready', downloadUrl: instance.logoDownloadUrl });
  }

  onSelectInstanceLogo(file: RcFile) {
    if (this.model.updateInstanceLogo) {
      this.updateLogoInstance({ ...this.model.updateInstanceLogo, logo: file });
    }
  }

  onRemoveInstanceLogo() {
    if (this.model.updateInstanceLogo) {
      this.updateLogoInstance({ ...this.model.updateInstanceLogo, logo: undefined });
    }
  }

  async onDeleteInstanceLogo() {
    handleError(await deleteInstanceLogo(this.api, this.getModel, this.update, this.instancesPeriodicRequest.refresh));
  }

  async onUpdateLogoConfirm(): Promise<void> {
    handleError(await updateInstanceLogo(this.api, this.getModel, this.update, this.instancesPeriodicRequest.refresh));
    this.update({ ...this.model, updateInstanceLogo: undefined });
  }

  onUpdateLogoCancel(): void {
    this.update({ ...this.model, updateInstanceLogo: undefined });
  }

  async onReassignInstance(instance: WbInstance): Promise<void> {
    void loadInstanceReassign(this.usersApi, instance, this.updateInstanceReassign);
  }

  onInstanceReassignSelectedUser(userSubject: UserAssignmentSpecifier): void {
    this.updateInstanceReassign(instanceReassignSetUser(this.model.reassignInstance, userSubject));
  }

  async onInstanceReassignConfirmed(): Promise<void> {
    handleError(
      await instanceReassignSave(this.api, this.model.reassignInstance, this.instancesPeriodicRequest.refresh, this.updateInstanceReassign)
    );
  }

  onInstanceReassignCanceled(): void {
    this.updateInstanceReassign(undefined);
  }

  async onRenameInstance(instance: WbInstance): Promise<void> {
    this.update(startRenameInstance(this.model, instance));
  }

  async onPauseInstance(instance: WbInstance): Promise<void> {
    this.update({ ...this.model, confirmPauseInstance: instance });
    handleError(await pauseInstance(this.api, this.getModel, this.update, this.instancesPeriodicRequest.refresh));
  }

  async onStaleInstancePauseConfig(instance: WbInstance): Promise<void> {
    handleError(await openStaleInstancePauseModal(this.usersApi, instance, this.getModel, this.update));
  }

  onScheduledPauseDaysChanged(scheduledPauseDays: number): void {
    this.update(updateStaleInstancePause(this.model, { scheduledDays: scheduledPauseDays }));
  }

  async onScheduledPauseDone(): Promise<void> {
    handleError(await staleInstancePauseConfig(this.api, this.getModel, this.instancesPeriodicRequest.refresh, this.update));
  }

  onScheduledPauseCancelled(): void {
    this.update(closeStaleInstancePauseModal(this.model));
  }

  async onDailyScheduledPauseInstance(instance: WbInstance): Promise<void> {
    void loadDailyScheduledPause(this.api, instance, this.updateDailyScheduledPause);
    void loadDailyScheduledPause(this.api, instance, this.updateInstanceDailyScheduledPause);
  }

  onDailyScheduledPauseTimezoneChanged(timezone: string): void {
    this.update(updateDailyInstancePause(this.model, { timezone }));
  }

  onDailyScheduledPauseTimeChanged([dateFromObj, dateToObj]: [Dayjs, Dayjs], fromTime: string, toTime: string): void {
    dayjs.extend(duration);
    const hoursDiff = dayjs.duration(Math.abs(dateFromObj.diff(dateToObj))).asHours();
    let dateError: string | undefined = undefined;
    if (hoursDiff < 3) {
      dateError = 'Please select a longer time interval (min 3 hours)';
    }
    this.update(updateDailyInstancePause(this.model, { fromTime, toTime, dateError }));
  }

  onDailyScheduledPauseIsPausedOnWeekendsChange(isPausedOnWeekends: boolean) {
    this.update(updateDailyInstancePause(this.model, { isPausedOnWeekends }));
  }

  async onDailyScheduledPauseDone(): Promise<void> {
    handleError(await dailyInstanceScheduledPause(this.api, this.getModel, this.instancesPeriodicRequest.refresh, this.update));
  }

  onDailyScheduledPauseCancelled(): void {
    this.update(closeDailyInstancePauseModal(this.model));
  }

  async onDailyScheduledPauseRemoved(): Promise<void> {
    handleError(await dailyInstanceScheduledPauseRemove(this.api, this.getModel, this.instancesPeriodicRequest.refresh, this.update));
  }

  async onResumeInstance(instance: WbInstance): Promise<void> {
    this.update({ ...this.model, confirmResumeInstance: instance });
    handleError(await resumeInstance(this.api, this.getModel, this.update, this.instancesPeriodicRequest.refresh));
  }

  onNameChanged(name: string): void {
    this.update(updateInstanceRename(this.model, { name, nameError: '' }));
  }

  async onRenameDone(): Promise<void> {
    handleError(await renameInstance(this.api, this.getModel, this.instancesPeriodicRequest.refresh, this.update));
  }

  onRenameCancelled(): void {
    this.update(stopRenameInstance(this.model));
  }

  async onRestartInstance(instance: WbInstance): Promise<void> {
    this.update({ ...this.model, confirmRestartInstance: instance });
  }

  async onRestartInstanceSelected(restart: boolean): Promise<void> {
    if (restart) {
      handleError(await restartInstance(this.api, this.getModel, this.update, this.instancesPeriodicRequest.refresh));
    } else {
      this.update(restartInstanceCancelled(this.model));
    }
  }

  async onInstallPackagesToInstance(instance: WbInstance): Promise<void> {
    void loadInstallPackages(this.api, instance, this.updateInstanceInstallPackages);
  }

  async onInstallPackagesConfirm(): Promise<void> {
    handleError(await installPackages(this.api, () => asInstanceInstallPackages(this.model), this.updateInstanceInstallPackages));
  }

  onInstallPackagesCancel(): void {
    this.updateInstanceInstallPackages({ install: undefined, packages: undefined });
  }

  async onClearInstance(instance: WbInstance): Promise<void> {
    this.update({ ...this.model, confirmClearInstance: instance });
  }

  onClearInstanceSelected(clear: boolean): void {
    this.update(clearInstanceStorage(this.model, clear));
  }

  onSetInstanceIsOpen(instance: WbInstance | string, isOpen: boolean) {
    this.update(setInstanceIsOpen(this.getModel(), typeof instance === 'string' ? instance : instance.id, isOpen));
  }

  async onTakeInstance(instance: NonOwnedInstance) {
    this.update(updateTakingOwnershipInProgress(this.model, instance.id, true));

    const currentUserId = await getUserSubject();
    if (!currentUserId) {
      handleError({ message: 'No current user' });
      this.update(updateTakingOwnershipInProgress(this.model, instance.id, false));
      return;
    }

    const response = await this.api.reassignInstance({ id: instance.id, user: { userId: currentUserId } });

    if (!response?.ok) {
      handleError({ message: 'Failed to take back instance.' });
      this.update(updateTakingOwnershipInProgress(this.model, instance.id, false));
      return;
    }

    await this.instancesPeriodicRequest.refresh();
  }

  async onUpdateAllInstances() {
    const eligibleInstances = this.getModel().instances.filter((instance) => canUpdateInstance(instance));

    this.updateUpgradeAllInstances({
      ids: eligibleInstances.map(({ id }) => id),
      names: eligibleInstances.map(({ name, image }) => `${name} (${image?.split(':').pop()})`),
      state: 'ready',
    });
  }
}
