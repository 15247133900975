import { AxiosRequestConfig } from 'axios';

import { getAccessToken } from './auth';

export async function axiosOptions(): Promise<AxiosRequestConfig> {
  const token = await getAccessToken();
  return { validateStatus: null, headers: { Authorization: `Bearer ${token}` } };
}

export async function blobAxiosOptions(): Promise<AxiosRequestConfig> {
  const token = await getAccessToken();
  return { responseType: 'blob', validateStatus: null, headers: { Authorization: `Bearer ${token}` } };
}
