import React, { Suspense, useCallback, useEffect, useState } from 'react';
import { ConfigProvider, Layout } from 'antd';
import { HashRouter as Router, Outlet, Route, Routes } from 'react-router-dom';

import { SignedInUser } from './services/auth';

import { SideNavRouteProps, sideNavRoutes } from './_shared/AppRoutes';

import { AppThemeContext, useThemeContextValue } from './hooks/use-theme';

import AppFooter from './pages/app-footer/AppFooter';
import { useDashboards } from './pages/dashboards/dashboard-hooks';
import { useDocumentation } from './pages/documentation/documentation-hooks';
import { InstanceListModel } from './pages/instances/models/instance-list';
import { InstanceListDelegate } from './pages/instances/views/InstanceList';
import { useJobs } from './pages/jobs/jobs-hooks';
import { useServices } from './pages/services/services-hooks';
import { UsageWarningScreen } from './pages/usage/views/UsageWarningScreen';
import { FLAG_ENABLE_WB_INSIDE_ZOE } from './pages/user-info/models/user-info';
import { useUserInfo } from './pages/user-info/user-info-hook';

import { useNotifications } from './secondary-sidebar/controls/notifications/notifications-hooks';
import { NotificationRelatedItemDispatcher } from './secondary-sidebar/controls/notifications/views/NotificationRelatedItemDispatcher';
import { SecondarySidebar, SecondarySidebarState } from './secondary-sidebar/SecondarySidebar';

import { AppContentWbInsideZoe } from './AppContentWbInsideZoe';

import SideNav, { SideNavProps } from './side-nav/SideNav';

import getAntDTheme from './utils/antd-theme';

import versionInfo from './version-info.json';

import './theme.common.less';
import './theme.dark.less';
import './theme.light.less';

const { Content } = Layout;

export interface FakeCognitoAttributes {
  email: string;
  phone_number: string;

  [key: string]: string;
}

export function WorkspaceAuthenticatedApp({ user }: { user: SignedInUser }) {
  const [sidebarMenuCollapsed, setSidebarMenuCollapsed] = useState(false);
  const [sidebarNotificationsCollapsed, setSidebarNotificationsCollapsed] = useState(SecondarySidebarState.Collapsed);

  const themeContextValue = useThemeContextValue();
  const { dashboardModel, dashboardDelegate } = useDashboards();
  const { servicesModel, servicesDelegate } = useServices();
  const { jobsModel, jobsDelegate } = useJobs();
  const { documentationModel } = useDocumentation();
  const { notificationsModel, notificationsDelegate } = useNotifications();
  const { userInfoModel, userInfoDelegate } = useUserInfo();

  useEffect(() => {
    void (async () => {
      try {
        await userInfoDelegate?.onAppStartedWithUser();
        userInfoDelegate?.onAppStartedInsideWorkspaces();
      } catch (e) {
        console.error('[wow] failed to initialise...', e);
      }
    })();
  }, [userInfoDelegate]);

  const wbInsideZoe = userInfoModel.featureFlags.includes(FLAG_ENABLE_WB_INSIDE_ZOE);

  const [instancesModel, setInstancesModel] = useState<InstanceListModel | null>(null);
  const [instancesDelegate, setInstancesDelegate] = useState<InstanceListDelegate | null>(null);
  const [sideNavRouteParams, setSideNavRouteParams] = useState<SideNavRouteProps | null>(null);

  const handleSetInstancesModel = (model: InstanceListModel) => setInstancesModel(model);
  const handleSetInstancesDelegate = (delegate: InstanceListDelegate) => setInstancesDelegate(delegate);

  useEffect(() => {
    if (
      !dashboardModel ||
      !dashboardDelegate ||
      !jobsModel ||
      !jobsDelegate ||
      !servicesModel ||
      !servicesDelegate ||
      !notificationsModel ||
      !notificationsDelegate ||
      !userInfoModel ||
      !documentationModel
    ) {
      return;
    }

    const updatedParams: SideNavRouteProps = {
      dashboardModel,
      dashboardDelegate,
      jobsModel,
      jobsDelegate,
      servicesModel,
      servicesDelegate,
      notificationsModel,
      notificationsDelegate,
      userInfoModel,
      documentationModel,
    };

    if (instancesModel) {
      updatedParams.instancesModel = instancesModel;
    }

    if (instancesDelegate) {
      updatedParams.instancesDelegate = instancesDelegate;
    }

    setSideNavRouteParams(updatedParams);
  }, [
    dashboardModel,
    dashboardDelegate,
    jobsModel,
    jobsDelegate,
    servicesModel,
    servicesDelegate,
    notificationsModel,
    notificationsDelegate,
    userInfoModel,
    documentationModel,
    instancesModel,
    instancesDelegate,
  ]);

  const toggleNotificationsSidebar = useCallback(() => {
    setSidebarNotificationsCollapsed((state) => {
      if (state !== SecondarySidebarState.Hidden) {
        return SecondarySidebarState.Hidden;
      } else {
        return SecondarySidebarState.Collapsed;
      }
    });
  }, []);

  if (!dashboardDelegate || !jobsDelegate || !servicesDelegate || !userInfoDelegate || !sideNavRouteParams) {
    return null;
  }

  const loadingElement = <div>Loading&#8230;</div>;
  if (userInfoModel.loading) {
    return loadingElement;
  }

  const navParams: SideNavProps & SideNavRouteProps = {
    ...sideNavRouteParams,
    userInfoDelegate,
    signOut: () => {},
    user,
  };

  const visibleSidebar = sidebarNotificationsCollapsed !== SecondarySidebarState.Hidden;

  return (
    <AppThemeContext.Provider value={themeContextValue}>
      <ConfigProvider theme={getAntDTheme(themeContextValue.theme)}>
        <UsageWarningScreen />
        <Router>
          <Suspense fallback={loadingElement}>
            <Routes>
              <Route
                path="/"
                element={
                  <div className={`app-background ${visibleSidebar ? 'visible-sidebar' : 'non-visible-sidebar'}`}>
                    <div className="pwa-header">LSEG Tick History Workbench</div>
                    <NotificationRelatedItemDispatcher notificationsDelegate={notificationsDelegate} />
                    <Layout>
                      {wbInsideZoe ? (
                        <AppContentWbInsideZoe
                          navParams={{ ...navParams }}
                          notificationsModel={notificationsModel}
                          notificationsDelegate={notificationsDelegate!}
                          sidebarMenuCollapsed={sidebarMenuCollapsed}
                          setSidebarMenuCollapsed={setSidebarMenuCollapsed}
                          sidebarNotificationsCollapsed={sidebarNotificationsCollapsed}
                          setSidebarNotificationsCollapsed={setSidebarNotificationsCollapsed}
                          toggleNotificationsSidebar={toggleNotificationsSidebar}
                          setInstancesModel={handleSetInstancesModel}
                          setInstancesDelegate={handleSetInstancesDelegate}
                        />
                      ) : (
                        <>
                          <SideNav
                            {...navParams}
                            sidebarCollapsed={sidebarMenuCollapsed}
                            onSidebarCollapsed={setSidebarMenuCollapsed}
                            notificationsCollapsed={sidebarNotificationsCollapsed}
                            onNotificationsCollapsed={setSidebarNotificationsCollapsed}
                            onToggleNotificationsSidebar={toggleNotificationsSidebar}
                          />
                          <Content
                            className={sidebarNotificationsCollapsed !== SecondarySidebarState.Hidden ? 'secondary-sidebar-visible' : ''}
                          >
                            <Outlet />
                          </Content>
                          {visibleSidebar && (
                            <SecondarySidebar
                              notificationsModel={notificationsModel}
                              notificationsDelegate={notificationsDelegate}
                              sidebarCollapsed={sidebarNotificationsCollapsed === SecondarySidebarState.Collapsed}
                              onSidebarCollapsedChanged={(collapsed) =>
                                setSidebarNotificationsCollapsed(
                                  collapsed ? SecondarySidebarState.Collapsed : SecondarySidebarState.Visible
                                )
                              }
                            />
                          )}
                        </>
                      )}
                    </Layout>
                    {visibleSidebar && <AppFooter localVersionInfo={versionInfo} />}
                  </div>
                }
              >
                {sideNavRoutes(sideNavRouteParams)}
              </Route>
            </Routes>
          </Suspense>
        </Router>
      </ConfigProvider>
    </AppThemeContext.Provider>
  );
}
