import React, { CSSProperties, ReactNode } from 'react';

import styles from './Toolbar.module.css';

type ToolbarProps = {
  children: ReactNode | undefined;
  style?: CSSProperties | undefined;
  noForm?: boolean | undefined;
  className?: string | undefined;
  [prop: string]: unknown;
};

export function Toolbar(props: ToolbarProps) {
  const { style, children, noForm, className, ...rest } = props;
  const containerClass = `${styles.container} ${noForm ? styles.noFormContainer : ''} ${className || ''}`;
  return (
    <div className={containerClass} style={style} {...rest}>
      {children}
    </div>
  );
}
